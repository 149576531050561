import {Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef} from "@angular/core";
import {GeneralService} from "../../../services/general.service";
import * as _ from 'lodash';

@Component({
    selector: 'auto-complete-menu',
    templateUrl: `./auto-complete-menu.component.html`
})
export class AutoCompleteMenuComponent implements OnChanges, OnInit {
    @Input() autoCompleteItems;
    @Input() searchByKeys;
    @Input() displayKey;
    @Input() searchTerm;
    @Input() inputElementRef: HTMLInputElement;
    @ContentChild('customListTemplate', {static: false}) customListTemplate: TemplateRef<any>;
    @Output() onItemClick = new EventEmitter<any>();

    // Even though the search term was not found in any item, show it as is in results IF SUCCEED IN VALIDATION
    // showInputTextAsNewItemWhen is a validation function that returns boolean)
    @Input() showInputTextAsNewItemWhen;

    filteredItems = [];
    newItem = '';
    showMenu = false;

    constructor(private el: ElementRef,
                public gs: GeneralService) {
    }

    ngOnInit() {
        if (this.inputElementRef) {
            this.inputElementRef.addEventListener("keyup", (event) => {
                switch (event.key) {
                    case 'ArrowUp':
                        this.navigateInAutoCompleteResults('up');
                        break;
                    case 'ArrowDown':
                        this.navigateInAutoCompleteResults('down');
                        break;
                    case 'Enter':
                        if (this.showMenu) {
                            event.preventDefault();
                            this.onItemClick.emit({item: this.filteredItems[0]});
                        }
                        break;
                    case 'Escape':
                        this.showMenu = false;
                        break;
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.filterItems();

        this.showMenu = this.searchTerm && (!!this.filteredItems.length || !!this.newItem);
    }

    filterItems = () => {
        if (!this.searchTerm) {
            this.filteredItems = [];
            this.newItem = null;
            return;
        }

        if (this.searchByKeys) {
            let objectKeysToSearchIn = this.searchByKeys.split(',');
            objectKeysToSearchIn = _.map(objectKeysToSearchIn, key => key.trim());

            this.filteredItems = _.filter(_.cloneDeep(this.autoCompleteItems), item => {
                const relevantValues = _.values(_.pick(item, objectKeysToSearchIn));
                return _.some(relevantValues, val => val && val.toLowerCase().includes((this.searchTerm || '').toLowerCase()));
            });
        } else {
            this.filteredItems = _.filter(this.autoCompleteItems, item => item && item.toLowerCase().includes((this.searchTerm || '').toLowerCase()));
        }

        if (!this.filteredItems.length && this.showInputTextAsNewItemWhen) {
            if (this.showInputTextAsNewItemWhen(this.searchTerm)) {
                this.newItem = this.searchTerm;
            }
        } else {
            this.newItem = null;
        }

        if (this.filteredItems.length) {
            setTimeout(() => {
                const itemsInDOM = this.el.nativeElement.querySelectorAll('li');
                itemsInDOM.forEach((listItemEl, index) => {
                    if (index === 0 && !listItemEl.classList.contains('focused')) {
                        listItemEl.classList.add('focused');
                        return;
                    } else if (index !== 0) {
                        listItemEl.classList.remove('focused');
                    }
                });
            });
        }
    }

    // done by document.getElement because
    navigateInAutoCompleteResults = (direction) => {
        const currentFocusedListItem = _.find(this.el.nativeElement.querySelectorAll('li'), itemEl => itemEl.classList.contains('focused'));
        let nextItem, previousItem;

        if (currentFocusedListItem) {
            nextItem = currentFocusedListItem.nextElementSibling || currentFocusedListItem;
            previousItem = currentFocusedListItem.previousElementSibling || currentFocusedListItem;
        }

        const navigateToItem = direction === 'up' ? previousItem : nextItem;

        currentFocusedListItem.classList.remove('focused');
        navigateToItem.classList.add('focused');
        navigateToItem.focus();

    }

}
