import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {dic} from '../../../dictionary';
import {RestService} from '../../../services/rest.service';
import {TranslationService} from '../../../services/translation.service';

@Component({
    selector: 'app-message-sensitivity',
    templateUrl: './message-sensitivity.component.html',
    styleUrls: ['./message-sensitivity.component.scss']
})
export class MessageSensitivityComponent implements OnInit, OnDestroy {

    private sensitivitySubscription: Subscription;
    @Input() sensitivityInput: Observable<object>;
    sensitivity: any;
    messageDetails: any;

    sensitiveInfoMessage = '';
    sensitiveReportText = '';
    reportWrongSensitivityInProcess = false;
    showReportWrongSensitivity = false;

    constructor(
        private rs: RestService, private translateService: TranslationService) { }

    ngOnInit(): void {
        this.sensitivitySubscription = this.sensitivityInput.subscribe((data: any) => {
            if (data.sensitivity?.sensitive_types?.length) {
                data.sensitivity.sensitive_sub_types = data.sensitivity.sensitive_types.map(itm => itm.sensitive_type);
                data.sensitivity.compliance = data.sensitivity.sensitive_types.map(itm => itm.compliance);

                if (data.sensitivity.compliance?.length) {
                    data.sensitivity.compliance = Array.from(new Set(data.sensitivity.compliance.flat()));
                }
            }

            this.sensitivity = data.sensitivity;

            this.messageDetails = data.messageDetails;
            this.showReportWrongSensitivity = false;

            this.sensitiveInfoMessage = '';

            if (data.isUpdate) {
                this.sensitiveInfoMessage = this.translateService.getTranslationText('mailbox.sensitivity.sensitivityUpdate');
            }
        });
    }

    ngOnDestroy(): void {
        this.sensitivitySubscription.unsubscribe();
    }

    reportEmailSensitivity = () => {
        if (!this.sensitiveReportText) { return; }
        this.reportWrongSensitivityInProcess = true;
        this.rs.sendReport({
            type: dic.CONSTANTS.generalReportType.sensitivity,
            id: this.messageDetails._id,
            action: 'Email',
            reason: this.sensitiveReportText,
            name: this.messageDetails.title
        }).then(() => {
            this.reportWrongSensitivityInProcess = false;
            this.showReportWrongSensitivity = false;
            this.sensitiveInfoMessage = this.translateService.getTranslationText('mailbox.sensitivity.resultsReported');
        });
    };

}
