import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {GeneralService} from '../../../services/general.service';
import {RestService} from '../../../services/rest.service';
import {NotificationService} from '../../../services/notification.service';
import {Office365Service} from '../../../services/office365.service';
import {Observable, Subject, Subscription} from 'rxjs';
import moment from 'moment';
import {dic} from '../../../dictionary';

@Component({
	selector: 'app-message-tracking',
	templateUrl: './message-tracking.component.html',
	styleUrls: ['./message-tracking.component.scss']
})
export class MessageTrackingComponent implements OnInit, OnDestroy {

	@Input() currentMessage;
	@Input() showRecipientDetails;
	@Input() updateRecipientError;
	@Input() onUpdateRecipientDetails: (newContact: any) => any;
	@Input() onValidateRecipientPhone: (newContact: any) => any;
	@Input() onValidateContactName: (newContact: any) => any;
	@Input() autoRefreshTracking: (firstRun: boolean, prevRecipient: any) => any;
	@Output() updateRecipientTrack = new EventEmitter<object>();
	@Output() onInitMailbox = new EventEmitter();

	recipient: any;
	contactInfo: any;
	contactNameError = false;
	contactPhoneError = false;
	contactPhoneErrorSubject: Subject<boolean> = new Subject<boolean>();
	trackingDataSubject: Subject<Array<object>> = new Subject<Array<object>>();

	private recipientTrackSubscription: Subscription;
	@Input() recipientTrackInput: Observable<object>;
	recipientTrack:any = {track_events: []};

	private trackingDataSubscription: Subscription;
	@Input() trackingDataInput: Observable<object>;
	trackingData:any;

	private is_deleted: boolean;
	scheduled_time: any;
	start_sending_time: any;
	private minTimeSchedule;
	private _id: any;
	private editedScheduledTime;
	showScheduler = false;
	cancelScheduledTimeWarning = false;
	scheduleSendNowWarning = false;
	updateScheduledTimeWarning = false;

	constructor(private gs: GeneralService,
				private rs: RestService,
				private notificationService: NotificationService,
				private ngZone: NgZone) {
		this.minTimeSchedule = this.gs.round(moment().add(5, 'minutes'), moment.duration(5, "minutes"), "ceil").format();
	}

	ngOnInit() {
		this.recipientTrackSubscription = this.recipientTrackInput.subscribe((track:any) => {
			let state = false;
			if (this.recipientTrack.id === track.id) {
				state = this.recipientTrack.trackEventShow;
			}
			else {
				for (let i = 0; i < this.recipientTrack.track_events.length; i++) {
					this.recipientTrack.track_events[i].opened = false;
				}
			}
			this.recipientTrack = track;
			this.recipientTrack.trackEventShow = state;

		});

		this.trackingDataSubscription = this.trackingDataInput.subscribe((data:any) => {
			this.ngZone.run(() => {
				this.trackingData = data.trackingData;
				this.is_deleted = data.is_deleted;
				this.scheduled_time  = data.scheduled_time;
				this.start_sending_time = data.start_sending_time;
				this.showRecipientDetails = false;
				this._id = data._id;
			});

			setTimeout(() => {
				this.trackingDataSubject.next(this.trackingData);
			});
		});
	}

	ngOnDestroy() {
		this.recipientTrackSubscription.unsubscribe();
		this.trackingDataSubscription.unsubscribe();
	}

	collapseAllTrackEvents() {
		this.ngZone.run(() => {
			this.recipientTrack.trackEventShow = false;
			for (let i = 0; i < this.recipientTrack.track_events.length; i++) {
				this.recipientTrack.track_events[i].opened = false;
			}
		});
	}

	expandAllTrackEvents() {
		this.ngZone.run(() => {
			this.recipientTrack.trackEventShow = true;
			for (let i = 0; i < this.recipientTrack.track_events.length; i++) {
				this.recipientTrack.track_events[i].opened = true;
			}
		});
	}

	toggleTrackEvent(event) {
		this.ngZone.run(() => {
			event.opened = !event.opened;
		});
	}

	openLocation(ll) {
		const link = 'https://www.google.com/maps/search/?api=1&query=' + ll[0] + ',' + ll[1];
		window.open(link, '_blank');
	}

	initMailbox() {
		this.onInitMailbox.emit();
	}


	validateRecipientPhone({newContact, idx, missingPhone}) {
		this.contactPhoneError = this.onValidateRecipientPhone(newContact);
		this.contactPhoneErrorSubject.next(this.contactPhoneError);
	}

	validateContactName({newContact, idx}) {
		this.contactNameError = this.onValidateContactName(newContact);
	}

	openRecipientDetails(recipient: any) {
		if (!recipient) return;

		this.rs.getContactByEmail(recipient.email).then((response) => {
			this.showRecipientDetails = true;
			this.contactInfo = response;
		}, (err) => {
			this.showRecipientDetails = true;
			this.contactInfo = {email: recipient.email};
		});
	}

	updateRecipientDetails({newContact, idx}) {
		if (this.contactPhoneError || this.contactNameError) return;
		this.onUpdateRecipientDetails(newContact);
	}

	changeTrackingRecipient(track: any) {
		if (this.recipientTrack.id === track.id) return;
		this.recipientTrack = track;
		this.collapseAllTrackEvents();
		this.updateRecipientTrack.emit(this.recipientTrack);
	}

	updateCurrentMinTime = () => {
		this.minTimeSchedule = this.gs.round(moment().add(5, 'minutes'), moment.duration(5, "minutes"), "ceil").format();
	}

	setScheduleTime() {
		this.updateCurrentMinTime();
		if (this.editedScheduledTime < new Date(this.minTimeSchedule)) {
			this.editedScheduledTime = this.minTimeSchedule;
		}
	}

	minutesFilter = (d: Date): boolean => {
		const minutes = d.getMinutes();
		// Prevent not rounded minutes
		const isValid = minutes % 5 === 0;
		if (!isValid) {
			this.notificationService.showErrorMessage('compose.schedule.scheduleMinutesInvalid');
		}
		else {
			this.notificationService.clear();
		}
		return isValid;
	}

	cancelScheduledEmail = () => {
		this.rs.deleteMessage(this._id).then((response) => {
			this.initMailbox();
			this.cancelScheduledTimeWarning = false;
		});
	}

	scheduleSendNow = () => {
		this.rs.scheduleSendNow(this._id).then(() => {
			this.initMailbox();
			this.scheduleSendNowWarning = false;
		});
	}

	updateScheduledTime = () => {
		this.rs.updateScheduledTime({
			email_id: this._id,
			scheduled_time: this.editedScheduledTime
		})
		.then(() => {
			this.scheduled_time = this.editedScheduledTime;
			this.updateScheduledTimeWarning = false;
			this.showScheduler = false;
		});
	}

	clearEditScheduler = () => {
		this.showScheduler = false;
		this.editedScheduledTime = null;
	}

	getPickerCPositionClass() {
		if (document.body.clientHeight > 570) {
			return 'date-time-container-position-a';
		}
		else {
			return 'date-time-container-position-b';
		}
	}
}
