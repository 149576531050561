import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import fontawesome from '@fortawesome/fontawesome';
import faTrashAlt from '@fortawesome/fontawesome-free-regular/';

import { dic } from './dictionary';
import {GeneralService} from './services/general.service';
import {TranslationService} from './services/translation.service';
import {AuthService} from './services/auth.service';
import {Office365Service} from './services/office365.service';
import {RestService} from './services/rest.service';
import {LoginComponent} from "./components/login/login.component";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	@ViewChild(LoginComponent) loginEl:LoginComponent;

	dic = dic;

	constructor(private gs: GeneralService,
				public authService: AuthService,
				private translateService: TranslationService,
				public office365Service: Office365Service,
				private rs: RestService,
				private zone: NgZone) {
		this.authService.isAuthenticated();

		// Init fontawesome
		fontawesome.library.add(faTrashAlt);

		translateService.initLanguage();

		// response: 406
		this.rs.Restangular.configuration.require2FA.subscribe((require2FAData) => {
			if (!require2FAData) {return;}

			this.zone.run(() => {
				const isInsideLogin = !this.authService.isFullyAuthenticated;

				this.authService.userRequireMfa(require2FAData);

				//
				if (isInsideLogin) {
					setTimeout(() => {
						this.loginEl.ngOnInit();
					});
				}
			});
		});

		// response: 401
		this.rs.Restangular.configuration.blockUser.subscribe(blockUserData => {
			//this.authService.userNotAuthenticated(blockUserData);
		});
	}

	ngOnInit() {
	}
}
