import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'pin-code',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss']
})
export class PinCodeComponent implements OnInit, AfterViewInit{

  @ViewChildren('digitInput') digitInputs: QueryList<ElementRef>;

  // code length (number of digits)
  @Input() length: number;
  // the main model of the pincode
  @Input() pinCodeModel: any;
  @Output() pinCodeModelChange = new EventEmitter();
  // autoSubmit function (optional)
  @Output() autoSubmit ? = new EventEmitter<object>();
  @Output() onChange? = new EventEmitter();
  // "incorrect code" flag (optional. to show red borders.)
  @Input() incorrect?: any;
  @Output() incorrectChange? = new EventEmitter();

  // local models for digits
  localDigitModels = [];

  ngOnInit() {
    for (let i = 0; i < this.length; i++) {
      this.localDigitModels.push({value: '', error: false});
    }
  }

  ngAfterViewInit() {
    this.digitInputs.toArray()[0].nativeElement.focus();
  }

  keyDown(index, event) {
    this.localDigitModels[index].error = false;
    if (this.incorrect) {
      this.incorrectChange.emit(false);
    }

    const keyCode = event.keyCode;
    if (index === 0 && (event.ctrlKey || event.metaKey)) { // don't do 'preventDefault' when user hits "ctrl" key in first input, in order to allow pasting PIN code
      return;
    }

    // MAIN PRINCIPLE: Totally negate the effect of the user input ("preventDefault" - no "change" event and no ngModel in input), but catch the user's key press, validate it and apply it manually to HTML inputs and models
    event.preventDefault();

    switch (keyCode) {
      case 8: // "backspace" key
        this.digitInputs.toArray()[index].nativeElement.value = '';
        this.localDigitModels[index].value = '';
        // move to previous input and select it
        if (index !== 0) {
          this.digitInputs.toArray()[index - 1].nativeElement.focus();
          this.digitInputs.toArray()[index - 1].nativeElement.select();
        }
        this.updatePinCodeModel();
        return;

      case 46: // "delete" key
        this.digitInputs.toArray()[index].nativeElement.value = '';
        this.localDigitModels[index].value = '';
        this.updatePinCodeModel();
        return;

      case 37: // "ArrowLeft" key
        if (index !== 0) {
          this.digitInputs.toArray()[index - 1].nativeElement.focus();
          this.digitInputs.toArray()[index - 1].nativeElement.select();
        }
        return;

      case 39: // "ArrowRight" key
        if (index !== this.length - 1) {
          this.digitInputs.toArray()[index + 1].nativeElement.focus();
          this.digitInputs.toArray()[index + 1].nativeElement.select();
        }
        return;

      default:
        break;
    }

    if (keyCode < 48 || (keyCode > 57 && keyCode < 96) || keyCode > 105) { // non-number key (numpad numbers are also valid)
      if (!this.digitInputs.toArray()[index].nativeElement.value) {
        this.localDigitModels[index].error = true;
        setTimeout(() => {
          this.digitInputs.toArray()[index].nativeElement.value = '';
        })
        return;
      }
      return;
    }

    // apparently there are two non-valid scenarios that can "infiltrate" the above validation code: 1. a non-digit character in some mobile browsers. 2. "autofill" in some mobile browsers
    // next two code pieces are dedicated to these scenarios
    if (index === 0 && event.key && event.key.length === this.length) {
      if (event.key && event.key.trim().length === this.length && event.key.trim().match(/^[0-9]+$/)) {
        this.pasteCode(event.key);
        return;
      }
      else {
        this.localDigitModels[index].error = true;
        setTimeout(() => {
          this.digitInputs.toArray()[index].nativeElement.value = '';
        })
        return;
      }
    }
    if (!['0','1','2','3','4','5','6','7','8','9'].includes(event.key)) {
      this.localDigitModels[index].error = true;
      this.digitInputs.toArray()[index].nativeElement.value = '';
      return;
    }

    // validation end - pressed key is valid digit...

    // assign digit to model
    const isLastDigitNewAssign = index === this.length - 1 && !this.localDigitModels[this.length - 1].value;
    this.digitInputs.toArray()[index].nativeElement.value = event.key;
    this.localDigitModels[index].value = event.key;
    this.updatePinCodeModel();

    // valid number => move to next input or submit
    if (index < this.length - 1) {
      this.digitInputs.toArray()[index + 1].nativeElement.focus();
      this.digitInputs.toArray()[index + 1].nativeElement.select();
    }
    else if (isLastDigitNewAssign) { // auto-submit only if last digit input was changed from nothing to number
      if (this.autoSubmit && _.filter(this.localDigitModels, 'value').length === this.length) { // auto-submit only if all digits exists
        this.autoSubmit.emit();
      }
    }
  }

  updatePinCodeModel() {
    const code = _.map(_.filter(this.localDigitModels, 'value'), 'value').join('');
    this.pinCodeModelChange.emit(code)

    if (this.onChange) {
      this.onChange.emit();
    }
  }

  onPaste(event) {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');

    if (pastedText && pastedText.trim().length === this.length && pastedText.trim().match(/^[0-9]+$/)) {
      this.pasteCode(pastedText.trim());
    }
  }

  pasteCode(code) {
    for (let i = 0; i < this.length; i++) {
      this.localDigitModels[i] = {value: code[i], error: false};
      this.digitInputs.toArray()[i].nativeElement.value = code[i];
    }
    this.updatePinCodeModel();
    if (this.autoSubmit) {
      this.autoSubmit.emit();
    }
  }
}
