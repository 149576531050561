import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeneralService} from "../../services/general.service";
import {dic} from "../../dictionary";
import {Observable, Subscription} from "rxjs";
import util from "util";
import {NotificationService} from "../../services/notification.service";

@Component({
	selector: 'app-edit-contact',
	templateUrl: './edit-contact.component.html',
	styleUrls: ['./edit-contact.component.scss']
})
export class EditContactComponent implements OnInit, AfterViewInit {
	@Input() contact;
	@Input() index;
	@Input() isMailBox;
	@Input() updateRecipientError?;
	@Input() emailError;
	@Input() nameError;

	private phoneErrorSubscription: Subscription;
	@Input() phoneErrorObservable: Observable<boolean>;
	phoneError = false;

	@Output() onExecuteAction = new EventEmitter();
	@Output() onCancelAction = new EventEmitter();
	@Output() onValidateEmail = new EventEmitter();
	@Output() onValidatePhone = new EventEmitter();
	@Output() onValidateName = new EventEmitter();

	allowedCountries: any;
	private trustifiDefault: any;
	clone: any;
	random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

	constructor(private gs: GeneralService,
				private notificationService: NotificationService) { }

	ngOnInit() {
		this.phoneErrorSubscription = this.phoneErrorObservable.subscribe((val) => {
			this.phoneError = val;
			if (this.phoneError) {
				this.notificationService.showErrorMessage(dic.ERRORS.phoneInvalid, this.contact.email);
			}
			else {
				this.notificationService.clear();
			}
		});

		this.emailError = false;
		this.phoneError = false;
		this.nameError = false;
		this.allowedCountries = this.gs.allowedCountries;
		this.trustifiDefault = this.gs.trustifiDefault;
		this.clone = {
			_id: this.contact._id || this.contact.id,
			name: this.contact.name,
			email: this.contact.email,
			source: this.contact.source,
			phone: {
				phone_number: this.contact.phone && this.contact.phone.phone_number,
			},
			default_password: {
				password: this.contact.default_password && this.contact.default_password.password || '',
				hint: this.contact.default_password && this.contact.default_password.hint || ''
			}
		};
		if (this.contact.phone && this.contact.phone.country_code) {
			this.clone.phone.country_code = this.contact.phone.country_code;
			if (this.clone.phone.country_code[0] !== '+') {
				this.clone.phone.country_code = '+' + this.contact.phone.country_code;
			}
		}
		else {
			this.clone.phone.country_code = this.trustifiDefault.country_code;
		}
		if (this.clone._id) {
			this.gs.clonedContacts[this.clone._id] = this.clone;
		}
		else {
			this.gs.clonedContacts[this.clone.email] = this.clone;
		}
		this.updateRecipientError = '';
	}

	ngAfterViewInit(): void {
		const inputElements:any = document.getElementsByClassName('form-control');
		for (let i = 0; i < inputElements.length; i++) {
			inputElements[i].disabled = true;
		}
	}

	executeAction() {
		this.onExecuteAction.emit({newContact: this.clone, idx: this.index});
	}

	validateEmail() {
		if (this.onValidateEmail) {
			this.onValidateEmail.emit({newContact: this.clone, idx: this.index});
		}
	}

	validateName() {
		this.onValidateName.emit({newContact: this.clone, idx: this.index});
	}

	updateCountry(newCountryCode) {
		this.clone.phone.country_code = dic.CONSTANTS.countryCode[newCountryCode];
	}

	validatePhone() {
		this.onValidatePhone.emit({newContact: this.clone, idx: this.index, missingPhone: this.contact.missingPhone});
	}

	cancelAction() {
		this.onCancelAction.emit(this.index);
	}
}
