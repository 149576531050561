import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import {ENV_CONSTS} from '../../constants';
import {dic} from '../../dictionary';
import {Office365Service} from '../../services/office365.service';
import {GeneralService} from '../../services/general.service';
import {TranslationService} from '../../services/translation.service';
import * as _ from 'lodash';
import {RestService} from '../../services/rest.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	dic = dic;
	feBaseUrl = ENV_CONSTS.feBaseUrl;
	hideOpenWebAppBtn = ['613249f7009c6b000427b129']; // 613249f7009c6b000427b129 (Performantcorp)
	hideSupportContactBtn = ['613249f7009c6b000427b129']; // 613249f7009c6b000427b129 (Performantcorp)
	showLanguageMenu = false;
	trustifiDefault = this.gs.trustifiDefault;
	_ = _;

	constructor(public authService: AuthService,
				private notificationService: NotificationService,
				public translateService: TranslationService,
				private rs: RestService,
				public office365Service: Office365Service,
				public gs: GeneralService) {
	}

	ngOnInit() {
		if (this.gs.userInfo) {
			this.gs.getInitialTheme();
		}
	}

	refreshData() {
		this.gs.refreshDataSubj.next({});
	}

	showSupportForm() {
		this.gs.toggleSupportForm(true);
	}

	changeLanguage(langObj) {
		if (this.translateService.selectedAppLang !== langObj) {
			this.translateService.changeLanguageApp(langObj);
			this.showLanguageMenu = false;
		}
	}

	darkModeToggle = () => {
		this.gs.userInfo.dark_mode = !this.gs.userInfo.dark_mode;
		const actionInfo = {action: this.dic.CONSTANTS.userProfileActions.darkmode, enabled: this.gs.userInfo.dark_mode}
		this.rs.updateUserSettings(actionInfo).then(() => {

		});

		this.gs.getInitialTheme();
	}

	loadContacts() {
		const authenticatedUser = this.office365Service.roamingGet('userAuthenticated');
		this.notificationService.clear();
		this.authService.authForContacts(authenticatedUser.email, (err, newContactsNumber) => {
			if (err) {
				if (err !== true) {
					this.notificationService.showErrorMessage(err);
				}
				return;
			}

			const message = newContactsNumber ? dic.MESSAGES.importContacts : dic.MESSAGES.importNoContacts;
			this.notificationService.showInfoMessage(message);
			setTimeout(() => this.notificationService.clear(), 7000);

			this.gs.contactImportSubject.next({});
		});
	}

	logout() {
		this.authService.logout();
	}

}
