import {AfterContentInit, AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
	selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {

    @Input() public autoFocus: boolean;

    public constructor(private el: ElementRef) {}

    public ngAfterContentInit(): void {
        this.checkFocus(this.autoFocus);
    }

    public checkFocus(focus): void {
        if (focus) {
            setTimeout(() => {
                this.el.nativeElement.focus();
            });
        }
    }

}


// directive to calculate the dropdown available outer space. It determines weather the dropdown will show above it's trigger button or below it
/*@Directive({
    selector: '.dropdown'
})
export class closeToEdgeDropdown implements AfterContentInit{

    @Input() public dropdownClassName?: boolean;

    public constructor(private el: ElementRef) {}

    public ngAfterContentInit(): void {
        const triggerButton = this.el.nativeElement;
        const boundaryElement = triggerButton.closest(".DD-boundary");
        if (!boundaryElement) {
            return;
        }

        const boundaryElementPosition = boundaryElement.getBoundingClientRect();
        const triggerButtonPosition = triggerButton.getBoundingClientRect();

        let distanceFromTopBoundary = triggerButtonPosition.top - boundaryElement.top;
        if (boundaryElement.top < 0) { // that means that the top border of the boundary element is out of screen (was scrolled out of view)
            distanceFromTopBoundary = triggerButtonPosition.top;
        }

        let distanceFromBottomBoundary = boundaryElementPosition.bottom - triggerButtonPosition.bottom ;
        if (boundaryElementPosition.bottom > 0) { // that means that the top border of the boundary element is out of screen (was scrolled out of view)
            distanceFromTopBoundary = triggerButtonPosition.top;
        }

        // minimum of 3 rows in the dropped menu. default is drop menu BELOW
        if (distanceFromBottomBoundary > 35 * 3) {
            triggerButton.querySelector(".dropdown-menu").classList.add("show-below");
            return;
        }
        else if (distanceFromTopBoundary > 35 * 3) {
            triggerButton.querySelector(".dropdown-menu").classList.add("show-above");
        }
    }
}*/


@Directive({
	selector: '[scrollDrv]'
})
export class scrollDrv implements AfterViewInit {
	@Output() scrollDrv = new EventEmitter();

	globalInstance: any;

	public constructor(private el: ElementRef, private renderer: Renderer2) {
	}

	ngAfterViewInit() {
		this.globalInstance = this.renderer.listen(this.el.nativeElement, 'scroll', (e) => {
			let position = this.el.nativeElement.scrollTop / (this.el.nativeElement.scrollHeight - this.el.nativeElement.clientHeight);
			this.scrollDrv.emit(position);
		});
	}

	ngOnDestroy() {
		this.globalInstance();
	}
}


// Select all text in input (or element) when the given flag turns on
@Directive({
	selector: '[selectAllText]'
})
export class SelectAllText implements OnChanges, OnInit {

	@Input() selectAllText: boolean;

	public constructor(private element: ElementRef) {}

	ngOnInit() {
		if (this.selectAllText) {
			this.selectAll();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.selectAllText) {
			this.selectAll();
		}
	}

	selectAll(): void {
		setTimeout(() => {
			this.element.nativeElement.focus();
			this.element.nativeElement.select();
		});
	}
}
