import { Injectable } from '@angular/core';
import { dic } from '../dictionary';
import {ENV_CONSTS} from '../constants';
import {Office365Service} from './office365.service';
import {GeneralService} from './general.service';
import {RestService} from './rest.service';
import {Subject} from 'rxjs';
import * as Fingerprint2 from 'fingerprintjs2';

@Injectable({
	providedIn: 'root'
})
export class AccessClientService {

	userHash: string;
	data: string;
	timeout1;
	timeout2;
	link: any;

	private updateAuthDataSubject = new Subject<boolean>();
	updateAuthData$ = this.updateAuthDataSubject.asObservable();
	useSmartAuthentication = false;
	localMetrics: any;

	updateAuthData(data: any) {
		this.updateAuthDataSubject.next(data);
	}

	constructor(private office365Service: Office365Service,
				         private gs: GeneralService,
				         private rs: RestService) {}

	private createLocalMetricsFingerprint(cb) {
		let fpData = [];
		fixedFP(function() {
			cb(fpData);
		});

		function fixedFP(cb) {
			const options = {};
			Fingerprint2.get(options, function(components) {
				fpData = components || [];
				cb();
			}, err => {
				console.error(err);
				cb();
			});
		}
	}

	setAccessEnc = (enc) => {
		if (enc && enc.length > 0) {
			this.userHash = (enc.split('#')[1] || '').substring(1);

			const defaultHeaders = {};
			defaultHeaders[dic.HEADERS.xAccessEnc] = this.userHash;
			defaultHeaders[dic.HEADERS.xTrustifiSource] = 'outlook';

			const localStorageFp = this.useSmartAuthentication ? this.office365Service.roamingGet(dic.CONSTANTS.localStorageFp) : this.office365Service.roamingGet(this.userHash);
			if (localStorageFp) {
				defaultHeaders[dic.HEADERS.xFingerprint] = localStorageFp;
			}

			this.rs.setDefaultHeadersForAuthentication(defaultHeaders);
			return true;
		}
		return false;
	}

	getURL = (html) => {
		let match, links = [], regex = /href="([^'"]+)/g;
		while (match = regex.exec(html)) {
			if (match[1].indexOf(ENV_CONSTS.accessAppUrl) >= 0 || match[1].indexOf('https://e.trustifi.com') >= 0) {
				links.push(match[1]);
			}
		}

		return links;
	}

	getMessage = (url, cb) => {
		if (this.setAccessEnc(url)) {
			this.getAuthStatus(url, true, null, cb);
		} else {
			cb();
		}
	}

	getAuthStatus = (url, isFirst, localMetrics, cb) => {
		this.rs.getAuthStatus({isTrustifiAddin: 'outlook', metrics: localMetrics}).then(response => {
			if (!response) {
				return cb();
			}

			if (isFirst && response.status !== dic.STEP.Authenticated && response.smart_authentication) {
				this.useSmartAuthentication = true;
				this.setAccessEnc(url);
				this.createLocalMetricsFingerprint((localMetrics) => {
					console.log('localMetrics ?', localMetrics);
					this.localMetrics = localMetrics;
					this.getAuthStatus(url, false, this.localMetrics, cb);
				});
				return;
			}

			if (response && response.email_info &&
				(response.email_info.is_content_encrypted || response.email_info.status !== dic.STEP.Authenticated
					|| response.email_info.methods && response.email_info.methods.indexOf('Reply') > 0)) {
				this.data = response;
				this.updateAuthData(this.data);
				cb(true);
			} else {
				cb();
			}
		}, error => {
			if (error.data && error.data.status) {
				console.log(error.data);
				this.data = error.data;
				this.updateAuthData(this.data);
				cb(true);
			} else {
				cb();
			}
		});
	}
}
