import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {dic} from "../../dictionary";
import {Observable, Subscription} from "rxjs";
import * as _ from 'lodash';
import {AutoFocusDirective} from "../../directives/directives";

@Component({
	selector: 'app-search-dropdown',
	templateUrl: './search-dropdown.component.html',
	styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnInit, OnDestroy {

	// @ts-ignore
	@ViewChild(AutoFocusDirective) autoFocusDirective: AutoFocusDirective;

	dic = dic;
	dropdownShow = false;
	searchText = null;
	selectedObject;

	@Input() searchField;
	@Input() innerSearchField?;
	@Input() clickTooltip?;
	@Input() clickSelected;

	private dropdownObjectsSubscription: Subscription;
	@Input() dropdownObjectsInput: Observable<Array<object>>;
	dropdownObjects: any;
	originalDropdownObjects: any;

	@Output() clickSelectedCb?: EventEmitter<object> = new EventEmitter();
	@Output() changeSelectedCb: EventEmitter<object> = new EventEmitter();

	constructor(private ngZone: NgZone) {
	}

	ngOnInit(): void {
		this.dropdownObjectsSubscription = this.dropdownObjectsInput.subscribe((dropdownObjects:any) => {
			this.ngZone.run(() => {
				this.dropdownObjects = dropdownObjects;
				this.originalDropdownObjects = _.clone(this.dropdownObjects);
				this.selectedObject = this.originalDropdownObjects[0];
			});
		});
	}

	ngOnDestroy() {
		this.dropdownObjectsSubscription.unsubscribe();
	}

	toggleDropdown() {
		if (this.originalDropdownObjects.length > 1) {
			this.dropdownShow = !this.dropdownShow;
			this.autoFocusDirective.checkFocus(this.dropdownShow);
		}
	}

	clearSearch() {
		this.dropdownObjects = _.clone(this.originalDropdownObjects);
		this.searchText = null;
	}

	searchObject(searchText) {
		if (!searchText || searchText === '') {
			this.clearSearch();
			return;
		}

		searchText = searchText.toLowerCase();

		let toSearch;
		this.dropdownObjects = _.filter(this.originalDropdownObjects, (obj) => {
			toSearch = this.innerSearchField ? obj[this.innerSearchField] : obj;
			return toSearch[this.searchField].toLowerCase().indexOf(searchText) >= 0;
		});
	}

	clickObject(event) {
		if (!this.clickSelected) return;
		this.clickSelectedCb.emit(this.selectedObject);
		event.stopPropagation();
	}

	selectObject(object) {
		this.selectedObject = object;
		this.clearSearch();
		this.dropdownShow = false;
		this.changeSelectedCb.emit(this.selectedObject);
	}

}
