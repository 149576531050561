import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { dic } from '../../dictionary';
import {GeneralService} from '../../services/general.service';
import {Observable, Subscription} from 'rxjs';

@Component({
	selector: 'app-advanced',
	templateUrl: './advanced.component.html',
	styleUrls: ['./advanced.component.scss']
})
export class AdvancedComponent implements OnInit, OnDestroy {

	dic = dic;
	tab = dic.CONSTANTS.mailboxTabs.advanced;
	hasWKTextSecurity = true;
	showPassword = false;
	ddsComposeMethod = false;
	replyToEmailValidation = true;
	random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
	trustifiDefault;

	@Input() locked;
	@Input() methods;
	@Input() advanced;
	@Input() attachmentExist;
	@Input() isMailbox;
	@Input() isCustomized;
	@Input() showButtons;
	@Input() secureMethods;
	@Input() userPolicy;
	@Output() onSaveDefaults = new EventEmitter();
	@Output() onResetDefaults = new EventEmitter();
	@Output() onChangeMethodToPhone = new EventEmitter();

	private allRecipientsHasPasswordSubscription: Subscription;
	@Input() allRecipientsHasPasswordInput?: Observable<boolean>;
	allRecipientsHasPassword;

	constructor(private gs: GeneralService) {
		this.trustifiDefault = this.gs.trustifiDefault;
	}

	ngOnInit() {
		this.hasWKTextSecurity = this.gs.hasWKTextSecurity();

		if (this.allRecipientsHasPasswordInput) {
			this.allRecipientsHasPasswordSubscription = this.allRecipientsHasPasswordInput.subscribe((value) => {
				this.allRecipientsHasPassword = value;
			});
		}
	}

	ngOnDestroy() {
		if (this.allRecipientsHasPasswordSubscription) {
			this.allRecipientsHasPasswordSubscription.unsubscribe();
		}
	}

	saveDefaults = (isTmp) => {
		if (!this.isMailbox && this.advanced.email_me.on_link_clicked && this.advanced.email_me.on_link_clicked.value) {
			this.advanced.general.track_links.value = true;
		}
		this.onSaveDefaults.emit({data: this.advanced, isTmp: isTmp});
	}

	resetToDefaults = () => {
		this.onResetDefaults.emit((data) => {
			this.advanced = data;
		});
	}

	validateEmail = (email) => {
		return this.gs.validateEmail(email);
	}

	updateAuthMethod(method, event) {
		if (!this.secureMethods.includes(method)) {
			event.stopPropagation();
			return;
		}
		if (method === dic.CONSTANTS.authenticationMethod.phone) {
			this.validateRecipientsHasPhone();
		}
		this.advanced.secure.method_2factor.value = method;
		this.saveDefaults(true);
	}

	validateRecipientsHasPhone = () => {
		if (this.methods.secure_send.value) {
			this.onChangeMethodToPhone.emit();
		}
	}
}
