import {Inject, Injectable } from '@angular/core';
import {dic} from "../dictionary";
import {HttpClient, HttpHeaders, HttpRequest} from "@angular/common/http";
import {ENV_CONSTS} from "../constants";
import {Subject} from "rxjs";
import * as shaJs from 'sha.js';
import {Buffer} from 'safe-buffer';
import {GeneralService} from "./general.service";
import {RestService} from "./rest.service";
import * as $ from "jquery";
import * as util from 'util';
import * as _ from 'lodash';


//FOR DEBUG
const FORCE_EWS = false;

const SOAP_ENVELOPE:string = `<?xml version="1.0" encoding="utf-8"?>
<s:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xmlns:xsd="http://www.w3.org/2001/XMLSchema"
        xmlns:s="http://schemas.xmlsoap.org/soap/envelope/" 
        xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
<s:Header>
<t:RequestServerVersion Version="Exchange2010_SP1"/>
</s:Header>
<s:Body>
%s
</s:Body>
</s:Envelope>`;

const MIME_REQUEST:string = `<m:GetItem xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
  <m:ItemShape>
	<t:BaseShape>IdOnly</t:BaseShape>
	<t:IncludeMimeContent>true</t:IncludeMimeContent>
  </m:ItemShape>
  <m:ItemIds><t:ItemId Id="%s"/></m:ItemIds>
</m:GetItem>`;

const HEADERS_REQUEST:string = `<m:GetItem xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
  <m:ItemShape>
	<t:BaseShape>IdOnly</t:BaseShape>
	<t:BodyType>Text</t:BodyType>
	<t:AdditionalProperties>
		<t:ExtendedFieldURI PropertyTag="0x007D" PropertyType="String" />
	</t:AdditionalProperties>
  </m:ItemShape>
  <m:ItemIds><t:ItemId Id="%s"/></m:ItemIds>
</m:GetItem>`;

const ATTACHMENT_REQUEST:string = `<m:GetAttachment xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
  <m:AttachmentShape>
   <t:IncludeMimeContent>true</t:IncludeMimeContent>
   <t:BodyType>Text</t:BodyType>
  </m:AttachmentShape>
  <m:AttachmentIds>%s</m:AttachmentIds>
</m:GetAttachment>`;


@Injectable({
	providedIn: 'root'
})
export class Office365Service {

	constructor(private gs: GeneralService,
				private rs: RestService,
				private http: HttpClient) { }

	restToken = {
		token: null,
		expired: null,
		email: null,
		tid: null,
		restUrl: null
	};

	ewsToken = {
		token: null,
		expired: null,
		email: null,
		tid: null,
		ewsUrl: null
	};

	accountType = '';
	currentPage;
	profile;

	internetMessageId: string;
	itemClass: string;

	isWindowsDesktop = Office.context.mailbox.diagnostics.hostName !== 'OutlookWebApp' && window.navigator.platform.indexOf('Win') >= 0;
	isSafari = Office.context.mailbox.diagnostics.hostName === 'OutlookWebApp' && /(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent);
	isOutlookClient = Office.context.mailbox.diagnostics.hostName !== 'OutlookWebApp';

	//for read-mode message only, compose-mode message require async methods
	//we can get attachments only in the read mode
	//subject without "RE:" or "FW:": Office.context.mailbox.item.normalizedSubject;

	logout() {
		this.rs.setDefaultHeaders({});
		this.isOutlookClient && window.localStorage.clear();
		this.isOutlookClient && window.sessionStorage.clear();
		//deleting illegal value
		this.roamingSet('this.userAuthenticated', null, () => {});

		this.roamingSet('userAuthenticated', {}, () => {
			this.isOutlookClient && window.sessionStorage.clear();
			this.roamingSet('userAuthenticated', null, () => {
				this.isOutlookClient && window.sessionStorage.clear();
			});
		});

		this.currentPage = null;
	}

	getLocation() {
		try {
			return Office.context.mailbox.item.location;
		}
		catch(ex) {
			console.error('[Trustifi] cannot get item location', ex);
		}
	}

	updateCurrentPage() {
		this.currentPage = this.isCompose() ? dic.CONSTANTS.appPages.compose : dic.CONSTANTS.appPages.mailbox;
	}

	getBodyHTML(cb) {
		try {
			Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, res => {
				if(res.status === Office.AsyncResultStatus.Failed) {
					console.error('[Trustifi] getAsync HTML', res.error);

					Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, res => {
						if(res.status === Office.AsyncResultStatus.Failed) {
							console.error('[Trustifi] getAsync Text', res.error);
						}

						res['type'] = Office.CoercionType.Text;
						cb(res);
					});
				}
				else {
					res['type'] = Office.CoercionType.Html;
					cb(res);
				}
			});
		}
		catch(ex) {
			console.error('[Trustifi] getBodyHTML', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	};

	getBodyText() {
		return new Promise((resolve, reject) => {
			try {
				Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, res => {
					if (res.status === Office.AsyncResultStatus.Failed) {
						console.error('[Trustifi] getBodyText', res.error);
						return reject();
					}

					resolve(res.value);
				})
			}
			catch(ex) {
				console.error('[Trustifi] getBodyText', ex);
				reject();
			}
		});
	}

	getResourceId() {
		let restItemId = Office.context.mailbox.item.itemId;
		if (restItemId && Office.context.mailbox.diagnostics.hostName !== 'OutlookIOS') {
			restItemId = Office.context.mailbox.convertToRestId(restItemId, Office.MailboxEnums.RestVersion.v2_0);
		}
		return restItemId;
	}

	private getSelectedItems(cb) {
		if (!Office.context.requirements.isSetSupported('MailBox', '1.13')) {
			console.error('[Trustifi] selecting items is not supported by this API');
			return cb();
		}

		Office.context.mailbox.getSelectedItemsAsync(result => {
			if (result.status !== Office.AsyncResultStatus.Succeeded) {
				let restItemIds = result.value;
				if (restItemIds && Office.context.mailbox.diagnostics.hostName !== 'OutlookIOS') {
					for(let itm of restItemIds) {
						itm.itemId = Office.context.mailbox.convertToRestId(itm.itemId, Office.MailboxEnums.RestVersion.v2_0);
					}
				}

				cb(null, restItemIds);
			} else {
				cb(result);
			}
		});
	}

	private getRestUrl(fName, restUrl, cb) {
		if(restUrl) {
			return cb(null, restUrl);
		}

		//NOTE: the "this.gs.userInfo" should already exist so, we can theoretically flatten this function.
		this.gs.getUserInfo(false).then((userInfo) => {
			let planId = userInfo.plan && userInfo.plan._id && userInfo.plan._id.toString();
			restUrl = (planId === '60ad1289354477000427623d') ? 'https://aguamail.acbci.net/api' : 'https://outlook.office365.com/api';
			cb(null, restUrl);
		}, err => {
			this.rs.sendLogs('error', fName, {message:'getUserInfo Error', error: err.message});
			restUrl = 'https://outlook.office365.com/api';
			cb(null, restUrl);
		});
	}

	getAttachmentsContent(cb) {
		const fName = 'getAttachmentsContent';

		if(!Office.context.mailbox.item) {
			console.error('[Trustifi] email item doesnt exist');
			return cb();
		}

		let attachments = Office.context.mailbox.item.attachments.filter(itm => !itm.isInline);
		if (!attachments.length) {
			return cb();
		}

		if (FORCE_EWS) {
			return this.getAttachmentsContentEWS((err, attachments) => {
				cb(attachments);
			});
		}

		this.getAttachmentsContentRest((err, attachments) => {
			if (err) {
				this.getAttachmentsContentAPI((err, attachments) => {
					if (err) {
						this.getAttachmentsContentEWS((err, attachments) => {
							cb(attachments);
						});
					}
					else {
						cb(attachments);
					}
				});
			}
			else {
				cb(attachments);
			}
		});
	}

	addAttachment(url, filename, cb) {
		try {
			Office.context.mailbox.item.addFileAttachmentAsync(url, filename, cb);
		}
		catch(ex) {
			console.error('[Trustifi] Error', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	getHeaders(names, cb) {
		try {
			Office['cast'].item.toItemCompose(Office.context.mailbox.item).internetHeaders.getAsync(names, cb);
		}
		catch(ex) {
			console.error('[Trustifi] Error', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	setHeaders(headersObj, cb) {
		try {
			Office['cast'].item.toItemCompose(Office.context.mailbox.item).internetHeaders.setAsync(headersObj, cb);
		}
		catch(ex) {
			console.error('[Trustifi] Error', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	setSubject(text, cb) {
		try {
			Office['cast'].item.toItemCompose(Office.context.mailbox.item).subject.setAsync(text, cb);
		}
		catch(ex) {
			console.error('[Trustifi] Error', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	// in compose
	getSubject() {
		return new Promise((resolve, reject) => {
			try {
				Office['cast'].item.toItemCompose(Office.context.mailbox.item).subject.getAsync(res => {
					if (res.status === Office.AsyncResultStatus.Failed) {
						console.error('[Trustifi] getSubject', res.error);
						return reject();
					}

					resolve(res.value);
				});
			}
			catch(ex) {
				console.error('[Trustifi] getSubject', ex);
				reject();
			}
		});
	}

	// in read mode
	getEmailSubject() {
		try {
			return Office.context.mailbox.item.subject;
		}
		catch (ex) {
			console.error('[Trustifi] getEmailSubject', ex);
		}
	}

	getRecipients() {
		function stripDisplayName(displayName) {
			return (displayName || '').replace(/["',\s]+/g, ' ');
		}

		function getRecipientsOfSource(source, cb) {
			Office['cast'].item.toItemCompose(Office.context.mailbox.item)[source].getAsync(res => {
				if (res.status === Office.AsyncResultStatus.Failed) {
					console.error('[Trustifi] getRecipientsOfSource: ' + source, res.error);
					return cb(true);
				}
				const recipientsOfScope = _.map(res.value || [], itm => {
					return {
						emailAddress: itm.emailAddress,
						displayName: stripDisplayName(itm.displayName),
						source: source
					}
				});

				return cb(false, recipientsOfScope);
			});
		}

		return new Promise((resolve, reject) => {
			try {
				let allRecipients = [];

				getRecipientsOfSource('to', (err, res) => {
					if (err) { return reject(err); }
					allRecipients = allRecipients.concat(res);

					getRecipientsOfSource('cc', (err, res) => {
						if (err) { return reject(err); }
						allRecipients = allRecipients.concat(res);

						getRecipientsOfSource('bcc', (err, res) => {
							if (err) { return reject(err); }
							allRecipients = allRecipients.concat(res);

							resolve(allRecipients);
						});
					});
				});
			}
			catch(ex) {
				console.error('[Trustifi] getRecipients', ex);
				reject(ex);
			}
		});
	}

	setRecipients(recipients) {

		function setRecipientsIntoSource(source, cb) {
			const recipientsOfSource = _.filter(recipients, recipient => {
				const recipientSources = (recipient.source || 'to').split(',');
				return recipientSources.includes(source);
			});

			Office['cast'].item.toItemCompose(Office.context.mailbox.item)[source].setAsync(recipientsOfSource, res => {
				if (res.status === Office.AsyncResultStatus.Failed) {
					console.error('[Trustifi] setRecipientsIntoSource: ' + source, res.error);
				}

				cb();
			});
		}

		return new Promise((resolve, reject) => {
			try {
				setRecipientsIntoSource('to', () => {
					setRecipientsIntoSource('cc', () => {
						setRecipientsIntoSource('bcc', () => {
							resolve();
						});
					});
				});
			}
			catch(ex) {
				console.error('[Trustifi] setRecipients', ex);
				reject(ex);
			}
		});
	}

	addAll(recipients, cb) {
		try {
			let errors = {
				to: '',
				cc: '',
				bcc: ''
			};
			Office['cast'].item.toItemCompose(Office.context.mailbox.item).to.addAsync(recipients.filter(itm => !itm.source || itm.source === 'to'), res => {
				if (res.status === Office.AsyncResultStatus.Failed) {
					errors.to = res.error;
				}

				Office['cast'].item.toItemCompose(Office.context.mailbox.item).cc.addAsync(recipients.filter(itm => itm.source === 'cc'), res => {
					if (res.status === Office.AsyncResultStatus.Failed) {
						errors.cc = res.error;
					}

					Office['cast'].item.toItemCompose(Office.context.mailbox.item).bcc.addAsync(recipients.filter(itm => itm.source === 'bcc'), res => {
						if (res.status === Office.AsyncResultStatus.Failed) {
							errors.bcc = res.error;
						}

						res = {
							error: errors,
							status: errors.to || errors.cc || errors.bcc ? Office.AsyncResultStatus.Failed : Office.AsyncResultStatus.Succeeded
						};

						cb(res);
					});
				});
			});
		}
		catch(ex) {
			console.error('[Trustifi] addAll', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	showDialog(route, width, height) {
		//#9394 - when cookies disabled the localStorage is blocked in OWA.
		this.isOutlookClient &&  window.localStorage.removeItem('trustifi_authwnd_handler');

		return new Promise((resolve, reject) => {
			let dialog;
			try {
				window['trustifi_authwnd_handler'] = message => {
					try {dialog && dialog.close()} catch(ex) {
						console.log('ex ???', ex);
					}
					resolve({message: message});
				};

				Office.context.ui.displayDialogAsync(route, { height: height, width: width }, (asyncResult) => {
					if (asyncResult.status === Office.AsyncResultStatus.Failed) {
						console.error('[Trustifi] showDialog error: ', asyncResult.error);
						reject(dic.DIALOG_EVENTS[asyncResult.error.code] || asyncResult.error.message);
					} else {
						dialog = asyncResult.value;
						dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
							//let messageFromDialog = JSON.parse(arg.message);
							//this.showNotification('dlg', arg.message, Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage);

							//change child dialog URL:
							//window.location.href = "/newPage.html";

							//close dialog:
							dialog.close();
							resolve(arg);
						});

						dialog.addEventHandler(Office.EventType.DialogEventReceived, arg => {
							console.error('[Trustifi] showDialog error: ', arg);
							if(arg.error === 12006) {
								//#9394 - when cookies disabled the localStorage is blocked in OWA.
								if (this.isOutlookClient) {
									let message = window.localStorage.getItem('trustifi_authwnd_handler');
									if (message) {
										window.localStorage.removeItem('trustifi_authwnd_handler');
										return resolve({message: message});
									}
								}

								return reject(true);
							}

							reject(dic.DIALOG_EVENTS[arg.error] || arg.error);
						});

						/*dialog.eval = function(name) { return eval(name) }
						dialog.eval("this.getWindow = " + function() {
							return "Hacked " + username;
						}.toSource());*/
					}
				});
			}
			catch(ex) {
				console.error('[Trustifi] showDialog error: ', ex);
				reject(ex.message);
			}
		});
	};

	showWindow(route, width, height, cb) {
		let hwnd = window.open(route, '_blank', 'location=no,width='+width+',height='+height+',scrollbars=yes,top=100,left=100,resizable=no');
		try {
			hwnd.focus();
			hwnd.onunload = () => cb(true);
		}
		catch (e) {
			cb("Pop-up Blocker is enabled! Please add this site to your exception list");
		}

		hwnd['trustifi_authwnd_handler'] = message => {
			try {hwnd.close()} catch(ex) {}
			cb(null, {message: message});
		};
	};

	getUserProfile() {
		let user;
		try {
			user = Office.context.mailbox && Office.context.mailbox.userProfile;
			this.accountType = user && user.accountType;
		}
		catch(ex) {
			console.error('[Trustifi] userProfile error: '+ex.message, ex);
			this.accountType = null;
		}

		return {
			user: user || {},
			sender: Office.context.mailbox && Office.context.mailbox.item && Office.context.mailbox.item.sender || {},
			from: Office.context.mailbox && Office.context.mailbox.item && Office.context.mailbox.item.from || {}
		};
	}

	isCompose() {
		return !Office.context.mailbox.item.itemId;
	}

	private getRestAPIToken(fName, cb) {
		console.log('enter getRestAPIToken');

		if (this.accountType && (this.accountType === 'outlookCom' || this.accountType === 'gmail') || !Office.context.mailbox.getCallbackTokenAsync) {
			this.restToken.token = null;
			return cb({message: 'RestAPI token is not supported for this account type: '+this.accountType});
		}

		//the token is refreshed every 4min
		let maxExpirationTime = 250000;

		if (this.restToken.token && this.restToken.expired && (Date.now() < this.restToken.expired)) {
			let profile = this.getUserProfile();
			if (profile && profile.user && profile.user.emailAddress === this.restToken.email) {
				return cb(null, this.restToken.token, this.restToken.restUrl, this.restToken.email);
			}

			this.restToken.token = null;
			console.error('[Trustifi] cannot get mailbox or mailbox is different from token email address', {user:profile && profile.user && profile.user.emailAddress, token:this.restToken.email});
		}

		Office.context.mailbox.getCallbackTokenAsync({isRest: true}, result => {
			if (result && result.status === Office.AsyncResultStatus.Succeeded) {
				let payload:any = {};
				try {
					payload = atob(result.value.split('.')[1]);
					payload = JSON.parse(payload);
				}
				catch(ex) {
					console.error('[Trustifi] getCallbackTokenAsync parse JSON: '+ex.message, {token:result.value, ex});
					payload = {};
				}

				let exp = payload.exp && new Date(payload.exp*1000);
				if (exp && exp < new Date()) {
					let error = {message: 'Wrong system time, The token is already expired at: '+exp.toISOString()};
					this.restToken.token = null;
					this.rs.sendLogs('error', fName, {iat: payload.iat && new Date(payload.iat).toISOString(), error});
					return cb(error);
				}

				this.restToken = {
					token: result.value,
					expired: Date.now() + maxExpirationTime,
					tid: payload.tid,
					email: payload.email || payload.upn || payload.smtp || payload.preferred_username,
					restUrl: Office.context.mailbox.restUrl
				};

				//if "payload.exp" is missing we use it as a one-time token
				let expired = payload.exp && (payload.exp*1000) || 0;
				if (expired < this.restToken.expired) {
					this.restToken.expired = expired;
				}

				if (!this.restToken.restUrl && payload.aud && payload.aud.startsWith('http')) {
					this.restToken.restUrl = payload.aud + '/api';
				}

				this.getRestUrl(fName, this.restToken.restUrl, (err, restUrl) => {
					this.restToken.restUrl = restUrl;
					cb(null, this.restToken.token, this.restToken.restUrl, this.restToken.expired);
				});
			} else {
				this.restToken.token = null;
				this.rs.sendLogs('error', fName, {message:'getCallbackTokenAsync error', result});
				cb(result.error);
			}
		});
	}

	private getSharedMailboxItem(cb) {
		const fName = 'getSharedMailboxItem';

        if (!Office.context.mailbox.item.getSharedPropertiesAsync) {
            console.error("Try this sample on a message from a shared folder.");
            return cb();
        }

		if (!Office.context.requirements.isSetSupported('MailBox', '1.13')) {
			console.error('[Trustifi] shared properties is not supported by this API');
			return cb();
		}

		this.getRestAPIToken(fName, (err, token, restUrl, expired) => {
            if (err) {
				return cb(err);
			}

			Office.context.mailbox.item.getSharedPropertiesAsync(result2 => {
				let sharedProperties = result2.value;
				let delegatePermissions = sharedProperties.delegatePermissions;

				// Determine if user has the appropriate permission to do the operation.
				if ((delegatePermissions & Office.MailboxEnums.DelegatePermissions.Read) != 0) {
					let restId = this.getResourceId();
					let rest_url = sharedProperties.targetRestUrl + "/v2.0/users/" + sharedProperties.targetMailbox + "/messages/" + restId;

					this.http.get(rest_url, {
						headers: { Authorization: "Bearer " + token }
					}).toPromise().then((response) => {
						console.log(response);
						cb(null, response);
					}).catch((error) => {
						console.error(error);
						cb(error);
					});
				}
				else {
					cb();
				}
			});
        });
    }

	async getSharedMailboxUserId() {
		return new Promise((resolve, reject) => {
			if (Office.context.mailbox.item.getSharedPropertiesAsync) {
				Office.context.mailbox.item.getSharedPropertiesAsync(asyncResult =>  {
					if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
						console.error(`Getting shared properties failed with error: ${asyncResult.error.message}`);
						return reject(asyncResult.error);
					} else {
						return resolve(asyncResult.value.targetMailbox);
					}
				});
			} else {
				// non-shared item: return user id of primary mailbox user
				return resolve(Office.context.mailbox.userProfile.emailAddress);
			}
		});
	}

	private parseHeaders(rawHeaders) {
		if (!rawHeaders) {
			return [];
		}

		try {
			let lines = rawHeaders.split(/\r?\n/);
			for (let i = lines.length - 1; i > 0; i--) {
				if (/^\s/.test(lines[i])) {
					lines[i - 1] += '\n' + lines[i];
					lines.splice(i, 1);
				}
			}

			return lines.filter(line => line.trim()).map(line => ({
				Name: line.substring(0, line.indexOf(':')).trim().toLowerCase(),
				Value: line.substring(line.indexOf(':') + 1).trim()
			}));
		}
		catch(ex) {
			console.error('[Trustifi] parseHeaders Error', ex);
			return [];
		}
	}

	getEmailHeaders(cb) {
		const fName = 'getEmailHeaders';

		if (!Office.context.mailbox.item) {
			console.error('[Trustifi] email item doesnt exist');
			return cb();
		}

		this.getEmailHeadersRest((err, messageDetails) => {
			if (err) {
				this.getEmailHeadersAPI((err, messageDetails) => {
					if (err) {
						if (!messageDetails) {
							return cb();
						}

						this.getEmailHeadersEWS((err, headers) => {
							messageDetails.headers = headers || [];
							cb(messageDetails);
						});
					}
					else {
						cb(messageDetails);
					}
				});
			}
			else {
				cb(messageDetails);
			}
		});
	}

	getExchangeMessageId(cb) {
		const fName = 'getExchangeMessageId';

		if(!Office.context.mailbox.item) {
			let error = {message: 'email item doesnt exist'};
			console.error('[Trustifi] '+error.message);
			return cb(error);
		}

		this.itemClass = Office.context.mailbox.item.itemClass || 'IPM.Note';
		this.internetMessageId = Office.context.mailbox.item.internetMessageId;
		if (this.internetMessageId) {
			if (this.internetMessageId[0] === '<') {
				this.internetMessageId = this.internetMessageId.substring(1, this.internetMessageId.length - 1);
			}
			return cb(null, this.internetMessageId);
		}

		this.getExchangeMessage(fName, '?$select=InternetMessageId', 'json', (err, data) => {
			if(err) {
				return cb(err);
			}

			this.internetMessageId = data && data.InternetMessageId;
			if (this.internetMessageId && this.internetMessageId[0] === '<') {
				this.internetMessageId = this.internetMessageId.substring(1, this.internetMessageId.length - 1);
			}
			cb(null, this.internetMessageId);
		});
	}

	getExchangeMessageContent(cb) {
		const fName = 'getExchangeMessageContent';

		if(!Office.context.mailbox.item) {
			console.error('[Trustifi] email item doesnt exist');
			return cb({message: 'email item doesnt exist'});
		}

		if (FORCE_EWS) {
			return this.makeEwsRequest(fName, MIME_REQUEST, cb);
		}

		this.getExchangeMessage(fName, '/$value', 'text', (err, data) => {
			if(err) {
				return this.makeEwsRequest(fName, MIME_REQUEST, (err, data) => {
					if (err) {
						console.error('[Trustifi] MIME EWS error: '+err.message);
					}

					cb(err, data);
				});
			}

			cb(null, data);
		});
	}

	private getExchangeMessage(fName, querystring, responseType, cb) {

		let restItemId = this.getResourceId();
		if (!restItemId) {
			console.error('[Trustifi] failed to convert: ' + Office.context.mailbox.item.itemId, querystring);
			return cb({message: 'failed to convert ' + Office.context.mailbox.item.itemId});
		}

		this.getRestAPIToken(fName, (err, token, restUrl, expired) => {
			if (err) {
				return cb(err);
			}

			this.http.get(restUrl + '/v2.0/me/messages/' + restItemId + querystring, {
				headers: {'Authorization': 'Bearer ' + token},
				responseType: responseType
			}).subscribe((item: any) => {
				cb(null, item);
			}, err => {
				console.error('[Trustifi] exchange rest api error, expired: '+(Date.now()-expired), {error:err, accountType:this.accountType, messageId:this.internetMessageId, querystring});
				cb(err);
			});
		});
	}

	//[NOT IN USE]
	getExchangeFolder(folderId, responseType, cb) {
		const fName = 'getExchangeFolder';

		if (!folderId) {
			console.error('[Trustifi] folder ID is empty');
			return cb({message: 'folder ID is empty'});
		}

		this.getRestAPIToken(fName, (err, token, restUrl) => {
			if (err) {
				return cb(err);
			}

			this.http.get(restUrl + '/v2.0/me/MailFolders/' + folderId, {
				headers: {'Authorization': 'Bearer ' + token},
				responseType: responseType
			})
			.subscribe((item: any) => {
				cb(null, item);
			}, error => {
				console.error('[Trustifi] exchange rest api error: ', {error, folderId});
				cb(error);
			});
		});
	}

	replyEmail(html_content, cb) {
		Office.context.mailbox.item.displayReplyAllForm({htmlBody: html_content, callback: cb});
	}

	private getAccessToken(forceConsent, cb) {
		if (!Office.context.requirements.isSetSupported('IdentityAPI', '1.3')) {
			this.rs.sendLogs('error', 'getAccessToken', {message:'operation is not supported by this API'});
			cb({status: Office.AsyncResultStatus.Failed, error: 'IdentityAPI is not supported'});
		}

		//In Outlook, this API isn't supported if the add-in is loaded in an Outlook.com or Gmail mailbox.
		Office.auth.getAccessToken({forMSGraphAccess:true, allowConsentPrompt:forceConsent, allowSignInPrompt:forceConsent}).then(token => {
			cb(null, token);
		}, err => {
			console.error('[Trustifi] getAccessToken error: ', err);
			cb(err);
		});
	}

	private getIdentityToken(cb) {
		Office.context.mailbox.getUserIdentityTokenAsync(result => {
			if (result && result.status === Office.AsyncResultStatus.Succeeded) {
				cb(null, result.value);
			}
			else {
				console.error('[Trustifi] getIdentityToken error: ', result);
				cb({status: Office.AsyncResultStatus.Failed, error: result.error});
			}
		});
	}

	roamingClear(cb) {
		for (let key in Office.context.roamingSettings['settingsData']) {
			Office.context.roamingSettings.remove(key);
		}

		Office.context.roamingSettings.saveAsync(result => {
			if (result.status === Office.AsyncResultStatus.Failed) {
				this.rs.sendLogs('error', 'roamingSettings', {message:'error: roamingClear', result});
			}

			cb(result);
		});
	}

	roamingGet(name) {
		//const myPartitionKey = Office.context.partitionKey || ';
		let result = Office.context.roamingSettings.get('trustifi.' + name);
		if (!result && this.isWindowsDesktop) {
			result = window.localStorage.getItem('trustifi.' + name);
			try {
				if (result) {
					result = JSON.parse(result);
					this.roamingSet(name, result, () => {});
				}
			}
			catch(ex) {
				result = null;
			}
		}

		return result;
	}

	roamingSet(name, data, cb) {
		if (!data) {
			Office.context.roamingSettings.remove('trustifi.' + name);
			if(this.isWindowsDesktop) window.localStorage.removeItem('trustifi.' + name);
		}
		else {
			Office.context.roamingSettings.set('trustifi.' + name, data);
			if(this.isWindowsDesktop) window.localStorage.setItem('trustifi.' + name, JSON.stringify(data));
		}

		try {
			//https://web.dev/storage-for-the-web/
			if (navigator.storage && navigator.storage.estimate) {
				navigator.storage.estimate().then(quota => {
					// quota.usage -> Number of bytes used.
					// quota.quota -> Maximum number of bytes available.
					const percentageUsed = (quota.usage / quota.quota) * 100;
					console.log(`You've used ${percentageUsed}% of the available storage.`);
					const remaining = quota.quota - quota.usage;
					console.log(`You can write up to ${remaining} more bytes.`);
				}, err => {});
			}
		}
		catch(ex) {}

		try {
			Office.context.roamingSettings.saveAsync(result => {
				if (result.status === Office.AsyncResultStatus.Failed) {
					let settingsDataSize = JSON.stringify(Office.context.roamingSettings['settingsData']).length;
					this.rs.sendLogs('error', 'roamingSettings', {message:'error: ' + name + ', settingsData size is ' + settingsDataSize, result});

					//ISSUE #7139: we can remove all user roaming settings
					//this.roamingClear(result => {});
				}

				cb(result);
			});
		}
		catch(ex) {
			//this.rs.sendLogs('error', 'roamingSettings', {message:'error: ' + name, error:ex.message});
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	createMail(cb) {
		/*
		* toRecipients
		* ccRecipients
		* bccRecipients
		* htmlBody
		* subject
		* attachments
		* */
		try {
			Office.context.mailbox.displayNewMessageForm({
				subject: 'Trustifi Secure Mail',
				htmlBody: '<html><body><h1>Trustifi Secure Mail</h1></body></html>'
			});

			cb({status: Office.AsyncResultStatus.Succeeded });
		}
		catch(ex) {
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	showNotification(name, message, type) {
		if (!message) {
			return;
		}

		let options = {
			type: type || Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage,
			message: message.substring(0, 150)
		};
		if (type === Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage) {
			options['icon'] = 'icon16';
			options['persistent'] = true;
		}
		Office.context.mailbox.item.notificationMessages.addAsync(name, options);
	}

	//[NOT IN USE]
	moveEmail(cb) {
		const fName = 'moveEmail';

		if(!Office.context.mailbox.item) {
			console.error('[Trustifi] email item doesnt exist');
			return cb({message: 'email item doesnt exist'});
		}

		let restItemId = this.getResourceId();
		if (!restItemId) {
			console.error('[Trustifi] failed to convert: ' + Office.context.mailbox.item.itemId, this.internetMessageId);
			return cb({message: 'failed to convert ' + Office.context.mailbox.item.itemId});
		}

		this.getRestAPIToken(fName, (err, token, restUrl) => {
			if (err) {
				return cb(err);
			}

			this.http.post(restUrl + '/v2.0/me/messages/' + restItemId + '/move', {DestinationId: 'junkemail'}, {
				headers: {'Authorization': 'Bearer ' + token}
			}).subscribe((item: any) => {
				cb(null, item);
			}, error => {
				console.error('[Trustifi] exchange rest api error: ', {error, messageId:this.internetMessageId});
				cb(error);
			});
		});
	}

	private getAttachmentsContentEWS(cb) {
		const fName = 'getAttachmentsContentEWS';

		let attachments:any[] = Office.context.mailbox.item.attachments.filter(itm => !itm.isInline);
		if (!attachments.length) {
			return cb();
		}

		attachments = attachments.map(att => ({
			id: att.id,
			name: att.name,
			type: att.contentType,
			size: att.size
		}));

		let template = attachments.map(att => `<t:AttachmentId Id="${att.id}"/>`).join('\n');
		template = util.format(ATTACHMENT_REQUEST, template);
		this.makeEwsRequest(fName, template, (err, data) => {
			if (err) {
				console.error('[Trustifi] Attachments EWS error: '+err.message);
				return cb(err);
			}

			attachments.forEach((att,idx) => {
				if (!data[idx].content) return;

				let content = Buffer.from(data[idx].content, 'base64');
				att.content = data[idx].content;
				att.hash = shaJs('sha256').update(content).digest('hex');
				att.size = content.length;
			});

			cb(null, attachments);
		});
	}

	private getAttachmentsContentAPI(cb) {
		try {
			if (!Office.context.requirements.isSetSupported('MailBox', '1.8') || !Office.context.mailbox.item.getAttachmentContentAsync) {
				let error = 'attachment content is not supported by this API';
				console.error('[Trustifi] '+error);
				return cb({message: error});
			}

			let attachments:any[] = Office.context.mailbox.item.attachments.filter(itm => !itm.isInline);
			if (!attachments.length) {
				return cb();
			}

			attachments = attachments.map(att => ({
				id: att.id,
				name: att.name,
				type: att.contentType,
				size: att.size
			}));

			let promises = attachments.map(att => {
				let promise = new Promise<any>((resolve, reject) =>
					Office.context.mailbox.item.getAttachmentContentAsync(att.id, res => {
						if(res.status === Office.AsyncResultStatus.Failed) {
							console.error('[Trustifi] error extracting attachments', res);
							return reject(res.error);
						}

						let attachmentData;
						if (res.value) {
							//file formats can be: base64,url,eml,iCalendar. No binaries.
							if (res.value.format === 'base64') {
								attachmentData = Buffer.from(res.value.content, 'base64');
							} else {
								attachmentData = Buffer.from(res.value.content);
							}
						}

						att.content = attachmentData.toString('base64');
						att.hash = shaJs('sha256').update(attachmentData).digest('hex');
						att.size = attachmentData.length;
						resolve(att);
					}));

				return Promise.race([
					new Promise<any>(resolve => setTimeout(() => resolve(att), 5000)),
					promise
				])
			});

			Promise.all<any>(promises).then(res => {
				let attachments = res && res.filter(itm => itm);
				cb(null, attachments);
			}, err => {
				console.error('[Trustifi] error extracting attachments', err);
				cb(err);
			});
		}
		catch(ex) {
			console.error('[Trustifi] error extracting attachments', ex);
			cb(ex);
		}
	}

	private getAttachmentsContentRest(cb) {
		const fName = 'getAttachmentsContentRest';

		try {
			//It always returns no-attachments for signed email
			//let item = Office.context.mailbox.item.attachments;
			//if (!item.length) return cb();

			this.getRestAPIToken(fName, (err, token, restUrl, expired) => {
				if (err) {
					console.error('[Trustifi] Attachments REST Error: ' + err.message);
					return cb(err);
				}

				let restItemId = this.getResourceId();

				this.http.get(restUrl + '/v2.0/me/messages/' + restItemId + '/attachments', {
					headers: {'Authorization': 'Bearer ' + token}
				}).toPromise().then((res: any) => {
					let attachmentData, attachmentName, attachmentType, attachmentHash, attachmentSize, attachments = res.value;
					let attachmentList = [];
					let promises = [];

					for (let idx = 0; idx < attachments.length; idx++) {
						//console.log(attachments[idx]);

						if (attachments[idx].IsInline) { // Skip inline attachments
							continue;
						}

						if (attachments[idx]['@odata.type'] === '#Microsoft.OutlookServices.ItemAttachment') {
							promises.push([idx, this.http.get(restUrl + '/v2.0/me/messages/' + restItemId + '/attachments/' + attachments[idx].Id + '/$value', {
								headers: {'Authorization': 'Bearer ' + token},
								responseType: 'text'
							}).toPromise()]);

							continue;
						}

						if (attachments[idx].ContentBytes) {
							attachmentData = Buffer.from(attachments[idx].ContentBytes, 'base64');
							attachmentName = attachments[idx].Name;
							attachmentType = attachments[idx].ContentType;
							attachmentHash = shaJs('sha256').update(attachmentData).digest('hex');
							attachmentSize = attachments[idx].ContentBytes.length;
							attachmentList.push({
								name: attachmentName,
								type: attachmentType,
								content: attachmentData.toString('base64'),
								hash: attachmentHash,
								size: attachmentSize
							});
						}
					}

					Promise.all(promises.map(itm => itm[1])).then(res => {
						res.forEach((itm, idx) => {
							if (itm) {
								attachmentData = Buffer.from(itm, 'utf8');
								attachmentName = attachments[promises[idx][0]].Name + '.eml';
								attachmentType = attachments[promises[idx][0]].ContentType;
								attachmentHash = shaJs('sha256').update(attachmentData).digest('hex');
								attachmentSize = itm.length;
								attachmentList.push({
									name: attachmentName,
									type: attachmentType,
									content: attachmentData.toString('base64'),
									hash: attachmentHash,
									size: attachmentSize
								});
							}
						});

						cb(null, attachmentList);
					}, err => {
						console.error('[Trustifi] error extracting attachments, token expires in: '+(Date.now()-expired), {accountType:this.accountType, err});
						cb(null, attachmentList);
					});
				}, err => {
					console.error('[Trustifi] error extracting attachments, token expires in: '+(Date.now()-expired), {accountType:this.accountType, err});
					cb(err);
				});
			});
		}
		catch(ex) {
			console.error('[Trustifi] error extracting attachments', ex);
			cb(ex);
		}
	}

	private getEmailHeadersEWS(cb) {
		const fName = 'getEmailHeadersEWS';

		this.makeEwsRequest(fName, HEADERS_REQUEST, (err, headers) => {
			if (err) {
				console.error('[Trustifi] Headers EWS error: '+err.message);
				return cb(err);
			}

			headers = this.parseHeaders(headers);
			cb(null, headers);
		});
	}

	private getEmailHeadersAPI(cb) {
		let messageDetails = {
			headers: [],
			ReplyTo: [],
			toTrustifi: false,
			Id: this.getResourceId(),
			isMeetingRequest: Office.context.mailbox.item.itemType === 'appointment',
			InternetMessageId: this.internetMessageId,
			ToRecipients: Office.context.mailbox.item.to.map(itm => ({EmailAddress: {Name: itm.displayName, Address: itm.emailAddress}})),
			//CcRecipients: Office.context.mailbox.item.cc.map(itm => ({EmailAddress: {Name: itm.displayName, Address: itm.emailAddress}})),
			HasAttachments: !!Office.context.mailbox.item.attachments.filter(itm => !itm.isInline).length,
			Body: {ContentType:'HTML', Content:''},
			Subject: Office.context.mailbox.item.subject,
			InternetMessageHeaders: [],
			ParentFolderId: ''
		};

		messageDetails.toTrustifi = messageDetails.ToRecipients.some(itm => itm.EmailAddress.Address.indexOf(ENV_CONSTS.emailsuffix) >= 0);

		this.getBodyHTML(res => {
			if(res.status === Office.AsyncResultStatus.Succeeded) {
				messageDetails.Body.Content = res.value;
				messageDetails.Body.ContentType = res.type === Office.CoercionType.Html ? 'HTML':'Text';
			}

			if (FORCE_EWS) {
				return cb(true, messageDetails);
			}

			if (!Office.context.requirements.isSetSupported('MailBox', '1.8') || !Office.context.mailbox.item.getAllInternetHeadersAsync) {
				let error = 'email headers are not supported by this API';
				console.error('[Trustifi] '+error);
				return cb({message: error}, messageDetails);
			}

			Office.context.mailbox.item.getAllInternetHeadersAsync(res => {
				if(res.status === Office.AsyncResultStatus.Succeeded) {
					messageDetails.headers = this.parseHeaders(res.value);
				}

				cb(res.error, messageDetails);
			});
		});
	}

	private getEmailHeadersRest(cb) {
		const fName = 'getEmailHeadersRest';

		if (FORCE_EWS) {
			return cb(true);
		}

		const select = '?$select=InternetMessageId,InternetMessageHeaders,replyTo,ToRecipients,HasAttachments,Body,Subject,ParentFolderId';
		this.getExchangeMessage(fName, select, 'json', (err, messageDetails) => {
			if(err) {
				return cb(err);
			}

			messageDetails.toTrustifi = messageDetails.ToRecipients && messageDetails.ToRecipients.some(itm => itm.EmailAddress.Address.indexOf(ENV_CONSTS.emailsuffix) >= 0);
			messageDetails.headers = messageDetails.InternetMessageHeaders || [];

			if (messageDetails.ReplyTo) {
				messageDetails.ReplyTo.forEach(itm => {
					if (itm && itm.EmailAddress && itm.EmailAddress.Address) {
						messageDetails.headers.push({Name: 'Reply-To', Value: itm.EmailAddress.Address});
					}
				});
			}

			if (messageDetails['@odata.type'] === '#Microsoft.OutlookServices.EventMessage') {
				messageDetails.isMeetingRequest = true;
			}

			cb(null, messageDetails);
		});
	}

	//NOTE: https://stackoverflow.com/questions/32851325/makeewsrequestasync-not-working-in-outlook-desktop-client
	//https://github.com/OfficeDev/office-js/issues/4124
	private makeEwsRequest(fName:string, requestTemplate:string, cb) {

		let itemId = Office.context.mailbox.item.itemId;
		if (!itemId || Office.context.mailbox.diagnostics.hostName === 'OutlookIOS' || !Office.context.mailbox.makeEwsRequestAsync) {
			let error = {message: 'EWS Request is not supported on your device'};
			this.rs.sendLogs('error', fName, {error});
			return cb(error);
		}

		if (requestTemplate.includes('%s')) {
			//itemId = Office.context.mailbox.convertToEwsId(itemId, Office.MailboxEnums.RestVersion.v2_0);
			requestTemplate = util.format(requestTemplate, itemId);
		}

		let getItemRequest = util.format(SOAP_ENVELOPE, requestTemplate);

		//In Outlook on the web, on Windows (starting in Version 2303 (Build 16225.10000)), and on Mac (starting in Version 16.73 (23042601)),
		// if the response exceeds 5 MB in size, an error message is returned in the asyncResult.error property.
		// In earlier versions of the Outlook desktop client, an error message is returned if the response exceeds 1 MB in size.

		//When you use the makeEwsRequestAsync method in add-ins that run in Outlook versions earlier than Version 15.0.4535.1004,
		// you must set the encoding value to ISO-8859-1 (<?xml version="1.0" encoding="iso-8859-1"?>).

		Office.context.mailbox.makeEwsRequestAsync(getItemRequest, (result) => {
			if (result.status === Office.AsyncResultStatus.Failed) {
				this.rs.sendLogs('error', fName, {message:'EWS api error', result});
				return cb(result.error);
			}

			if (!result.value) {
				let error = {message: 'Received empty EWS response'};
				this.rs.sendLogs('error', fName, {error});
				return cb(error);
			}

			let responseDom = $($.parseXML(result.value.replace(/&#x0;/g, '')));
			if (!responseDom) {
				return cb();
			}

			let response = responseDom.find("t\\:FileAttachment");
			if (response && response.length) {
				let attachments = [];
				response.each((idx,itm) => {
					attachments.push({
						name: $(itm).find("t\\:Name").text(),
						type: $(itm).find("t\\:ContentType").text(),
						content: $(itm).find("t\\:Content").text(),
						cid: $(itm).find("t\\:ContentId").text(),
					});
				});

				return cb(null, attachments);
			}

			response = responseDom.find("t\\:MimeContent").text();
			if (response) {
				response = Buffer.from(response, 'base64').toString();
				return cb(null, response);
			}

			response = responseDom.find("t\\:ExtendedProperty").text();
			if (response) {
				return cb(null, response);
			}

			let responseCode = responseDom.find("m\\:ResponseCode").text();
			if (responseCode && responseCode !== 'NoError') {
				return cb({message: 'EWS Error: '+responseCode});
			}

			cb();
		});
	}

	setUserProfile() {
		this.profile = this.getUserProfile();
	}
}

