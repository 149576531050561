import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as platform from 'platform';
import {RestService} from "../../services/rest.service";
import {dic} from "../../dictionary";
import {NotificationService} from "../../services/notification.service";
import {GeneralService} from "../../services/general.service";


@Component({
	selector: 'contact-us-component',
	templateUrl: './support.component.html',
	styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

	dic = dic;
	textMessage = '';
	messageTitle = '';
	ddsOSSelectedType = platform.os.family.replace('Chromium', '').trim();
	ddsPlatformSelected = Office.context.mailbox.diagnostics.hostName;
	ddsReasonSelected = '';
	ddsOS = false;
	dssPlt = false;
	dssRsn = false;
	ddsOtherOS = '';
	ddsOtherPlt = '';
	dssOSTypes = ['Linux', 'Windows 11', 'Windows 10', 'Mac', 'Android', 'iOS', 'Other'];
	dssPlatforms = ['Outlook Online', 'Outlook Desktop', 'Other'];
	dssReasons = ["Encryption", "Deliverability Issue", "Outbound Rules", "Message Failure", "Inbound Relay", "False Positive/Negative", "Plan/Account-Related", "User Interface/ Add-in", "Account Compromised Detection", "Other"];

	constructor(private rs: RestService,
				private ns:NotificationService,
				public gs:GeneralService) { }

	ngOnInit() {
		this.textMessage = '';
		if (this.ddsOSSelectedType === 'OS X') {
			this.ddsOSSelectedType = 'Mac';
		}
		if (["Ubuntu", "Debian", "Fedora", "Red Hat", "SuSE"].indexOf(this.ddsOSSelectedType) >= 0) {
			this.ddsOSSelectedType = 'Linux';
		}
		if (this.ddsOSSelectedType.indexOf('Windows') >= 0) {
			this.ddsOSSelectedType += ' ' + platform.os.version;
		}

		this.ddsPlatformSelected = !this.ddsPlatformSelected ? 'Other' :
			this.ddsPlatformSelected === 'OutlookWebApp' ? 'Outlook Online' : 'Outlook Desktop';

		if (this.dssOSTypes.indexOf(this.ddsOSSelectedType) < 0) {
			this.ddsOSSelectedType = this.ddsOSSelectedType.indexOf('Windows')>=0 ? 'Windows Other' : 'Other';
		}
	}

	sendMessageToSupport() {
		if (!this.textMessage) return;

		const ticketInfo = {
			title: this.messageTitle,
			type: this.ddsReasonSelected,
			message: this.textMessage,
			OS: this.ddsOSSelectedType,
			browser: this.ddsPlatformSelected
		};

		if (this.ddsOSSelectedType === 'Other' && this.ddsOtherOS) {
			ticketInfo.OS = this.ddsOtherOS;
		}

		if (this.ddsPlatformSelected === 'Other' && this.ddsOtherPlt) {
			ticketInfo.browser = this.ddsOtherPlt;
		}
		
		this.rs.supportContact(ticketInfo).then(r => {
			this.ns.showInfoMessage(dic.MESSAGES.supportContactUs);
			this.ns.notificationPriority();
			this.gs.toggleSupportForm(false);

			this.textMessage = '';
			this.messageTitle = '';
			this.ddsReasonSelected = '';
		});
	}
}
