import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {GeneralService} from "../../services/general.service";
import {TranslationService} from "../../services/translation.service";

@Component({
	selector: 'app-html-editor',
	template: `
		<iframe id="editorframe" src="summerlib/summerIndex.html" frameborder="0"
				[style.min-height]="height ? (height - 20) + 'px' : '100%'"
				[style.max-height]="height ? (height - 20) + 'px' : '100%'"
				style="width:100%;overflow:auto">
		</iframe>
		<div *ngIf="showButtons" style="margin:15px">
			<button type="button" class="btn"
					[ngClass]="{'load': loading}"
					style="height:30px !important; width: 120px;"
					(click)="onSave.emit(_html)"
					tooltip="{{'advanced.general.allowDownloadingAsEMLTooltip' | translate}}"
					max-width="140"
					placement="top">
				{{'general.update' | translate}}
			</button>
			<button class="btn" type="button"
					style="height:30px !important; float: right;margin-right: 3px; width: 120px;"
					(click)="onCancel.emit()">
				{{'general.cancel' | translate}}
			</button>
		</div>`
})

export class HtmlEditorComponent implements OnInit {
	summerOptions: any = null;
	iframe: any = null;
	_html: string = '';
	loading = true;

	@Input()
	get html(): string {
		return this._html;
	}

	set html(html: string) {
		this._html = html;
		this.iframe && this.iframe.contentWindow.update && this.iframe.contentWindow.update(html);
	}

	@Input() height?: number;
	@Input() showButtons: boolean;

	@Output() change? = new EventEmitter<string>();
	@Output() load? = new EventEmitter<object>();
	@Output() focusin? = new EventEmitter<object>();
	@Output() onSave = new EventEmitter<string>();
	@Output() onCancel = new EventEmitter<object>();

	constructor(private gs: GeneralService, private translateService: TranslationService) {
		let vm = this;

		this.summerOptions = {
			focus: true,
			buttons: {
				protect: this.ProtectButton,
				rtl: this.RTLButton.bind(this, 'ltr'),
				ltr: this.RTLButton.bind(this, 'rtl')
			},
			toolbar: [
				['style', ['bold', 'italic', 'underline']],
				['fontface', ['fontname']],
				['textsize', ['fontsize']],
				['insert', ['picture']],
				['fontclr', ['forecolor','backcolor']],
				['alignment', ['paragraph', 'lineheight']]
			],
			disableResizeEditor: true,
			placeholder: this.translateService.getTranslationText('recipient.replyTextPlaceholder'),
			callbacks: {
				onChange: function (contents, $editable) {
					vm._html = contents;
					vm.change.emit(contents);
				},
				onPaste: function (e) {
					vm._html = e;
					vm.change.emit(e);
				}
			}
		};
	}

	RTLButton(direction, context) {
		let button = context.ui.button({
			contents: '<i class="fa fa-toggle-' + (direction === 'rtl' ? 'right' : 'left') + '"/> ' + direction,
			tooltip: direction === 'rtl' ? 'Right-to-Left' : 'Left-to-Right',
			container: false,
			click: function (e) {
				context.invoke('editor.justify' + (direction === 'rtl' ? 'Right' : 'Left'));

				let range = window.getSelection().getRangeAt(0);
				let startContainer = <HTMLElement>range.startContainer;
				let endContainer = range.endContainer;

				if (startContainer === range.commonAncestorContainer) {
					while (!startContainer.style || !startContainer.style.textAlign) startContainer = <HTMLElement>startContainer.parentNode;
					startContainer.style.direction = direction;
				}
				else {
					let commons = Array.prototype.slice.call(range.commonAncestorContainer.childNodes);
					while (commons.indexOf(startContainer) <= 0) startContainer = <HTMLElement>startContainer.parentNode;
					while (commons.indexOf(endContainer) <= 0) endContainer = endContainer.parentNode;

					do {
						startContainer.style.direction = direction;
						startContainer = startContainer === endContainer ? null : <HTMLElement>startContainer.nextSibling;
					} while (startContainer);
				}
			}
		});

		return button.render();
	}

	ProtectButton(context) {
		let button = context.ui.button({
			contents: '<i class="fa fa-lock"/> Protect',
			tooltip: 'Hide the content in the mailbox',
			container: false,
			click: function () {
				function createCustomLink(object) {
					let linkNode = window['$']('<code class="protect">' + (object || 'CHANGE ME') + '</code>');
					linkNode.attr('data-attribute-value', object['code']);
					return linkNode[0]
				}

				let selected = window.getSelection().toString();
				context.invoke('editor.insertNode', createCustomLink(selected));
				context.invoke('editor.pasteHTML', '&zwnj;')
			}
		});
		return button.render();
	}

	ngOnInit() {
		if (this.height) {
			this.summerOptions.height = this.height - 105;
		}

		let iframe = this.iframe = window.frames['editorframe'];
		if ((iframe.contentWindow || iframe).init) {
			(iframe.contentWindow || iframe).focusinEvt = () => {
				this.focusin.emit();
			};
			(iframe.contentWindow || iframe).init(this.summerOptions);
			(iframe.contentWindow || iframe).update(this._html);
			this.gs.setSummernoteLogo(iframe.contentWindow || iframe);

			this.load.emit(iframe.contentWindow || iframe);
		}
		else {
			let _timer = setInterval(() => {
				if ((iframe.contentWindow || iframe).init) {
					clearInterval(_timer);
					(iframe.contentWindow || iframe).focusinEvt = () => {
						this.focusin.emit();
					};
					(iframe.contentWindow || iframe).init(this.summerOptions);
					(iframe.contentWindow || iframe).update(this._html);
					this.gs.setSummernoteLogo(iframe.contentWindow || iframe);

					this.load.emit(iframe.contentWindow || iframe);
					this.loading = false;
				}
			}, 500);
		}
	}
}
