import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GeneralService} from '../../services/general.service';
import {AuthService} from '../../services/auth.service';
import {RestService} from '../../services/rest.service';
import {TranslationService} from '../../services/translation.service';
import {dic} from '../../dictionary';
import {Office365Service} from "../../services/office365.service";
import {ComposeComponent} from "../compose/compose.component";
import {MailboxComponent} from "../mailbox/mailbox.component";

@Component({
    selector: 'content-component',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {

    @ViewChild(ComposeComponent) composeComponentEl: ComposeComponent;
    @ViewChild(MailboxComponent) mailboxComponentEl: MailboxComponent;

    dic = dic;

    isVerified = true;
    verificationEmail = '';
    verificationSent = false;
    userInfo;

    verificationSentInProcess = false;

    constructor(public gs: GeneralService,
                private authService: AuthService,
                private rs: RestService,
                public office365Service: Office365Service,
                private translateService: TranslationService,
                private zone: NgZone) {
        this.office365Service.updateCurrentPage();
    }

    ngOnInit() {
        this.gs.getUserInfo(false).then((userInfo) => {
            if (!userInfo) {
                return;
            }
            this.userInfo = userInfo;
            this.verificationEmail = userInfo && userInfo.email ? userInfo.email : 'your email account.';

            this.isVerified = userInfo && userInfo.email_verified;
            if (!this.isVerified) {
                this.checkVerificationEmail();
            }
        });
    }

    sendVerificationEmail() {
        if (this.verificationSentInProcess) {
            return;
        }
        this.verificationSentInProcess = true;
        this.rs.sendVerficationMail().then(() => {
            this.verificationSentInProcess = false;
            this.verificationSent = true;
        });
    }

    checkVerificationEmail() {
        this.rs.getUserVerified().then((response) => {
            this.zone.run(() => {
                this.isVerified = response?.email_verified;
                this.gs.userInfo.email_verified = response?.email_verified;
                if (response?.email_verified) {
                    // refresh
                    const activeComponent = this.office365Service.isCompose() ? this.composeComponentEl : this.mailboxComponentEl;
                    activeComponent.ngOnInit();
                }
            });
        });
    }

    logout() {
        this.authService.logout();
    }

}
