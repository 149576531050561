import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import { dic } from '../../dictionary';
import {ENV_CONSTS} from "../../constants";
import {TranslationService} from '../../services/translation.service';
import {Office365Service} from "../../services/office365.service";
import {GeneralService} from "../../services/general.service";
import {RestService} from "../../services/rest.service";
import {AuthService} from "../../services/auth.service";

@Component({
	selector: 'signup-form-component',
	templateUrl: './signup-form.component.html',
	styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent implements OnInit, AfterViewInit {

	@Output() cancelCb = new EventEmitter<any>();

	constructor(private gs: GeneralService,
				private translateService: TranslationService,
				private office365Service: Office365Service,
				private rs: RestService,
				private authService: AuthService) { }

	dic = dic;
	corpLinks = ENV_CONSTS.corpLinks;
	allowedCountries;
	trustifiDefault;
	random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
	hasWKTextSecurity;
	showPassword = false;
	showReTypePassword = false;
	inflogin = false;
	signupState: string;
	private signupData: any;
	private reTypePassword: string;
	private error: boolean;
	errMsg: string;
	private signingUpInProcess: boolean;
	signupCompletionData;
	navigateToAppAfterCompleteInProcess = false;
	phoneState: string = 'none';
	emailState: string = 'none';
	statePassword: string = 'none';
	passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
	validPassword: boolean;

	passwordError;
	lastNameError;
	firstNameError;
	emailError;
	reTypePasswordError;
	phoneNumberError;

	ngOnInit() {
		this.allowedCountries = this.gs.allowedCountries;
		this.trustifiDefault = this.gs.trustifiDefault;
		this.hasWKTextSecurity = this.gs.hasWKTextSecurity();
		this.signupState = dic.CONSTANTS.signupState.fillForm;
		this.signupData = {
			accountDetails: {
				phone: {country_code: this.trustifiDefault.country_code},
				company: this.trustifiDefault.name,
				firstName: '',
				lastName: ''
			}
		};
		this.reTypePassword = '';

		const profile = this.office365Service.getUserProfile();
		this.signupData.accountDetails.email = profile.user.emailAddress || '';
		if (profile.user && profile.user.displayName) {
			const names = profile.user.displayName.split(' ');
			this.signupData.accountDetails.firstName = names[0];
			this.signupData.accountDetails.lastName = names[1];
		}
		if (this.signupData.accountDetails.email.indexOf('@') < 0) {
			this.signupData.accountDetails.email = '';
		}
	}

	ngAfterViewInit(): void {
		const inputElements:any = document.getElementsByClassName('form-control');
		for (let i = 0; i < inputElements.length; i++) {
			inputElements[i].disabled = true;
		}
	}


	resetFieldError(fieldReference) {
		if (fieldReference) {
			fieldReference = null;
		}
		// if no parameters then reset all field errors
		else {
			this.passwordError = null;
			this.lastNameError = null;
			this.firstNameError = null;
			this.emailError = null;
			this.reTypePasswordError = null;
			this.passwordError = null;
		}
	}

	validateSignUpForm() {
		let isPassed = true;

		if (!this.signupData.accountDetails.lastName) {this.lastNameError = true; isPassed = false;}
		if (!this.signupData.accountDetails.firstName) {this.firstNameError = true; isPassed = false;}

		if (this.signupData.accountDetails.phone && this.signupData.accountDetails.phone.phone_number) {
			// check if the phone number contains letters or longer than 16 digits
			if (!(Math.floor(Number(this.signupData.accountDetails.phone.phone_number)) === Number(this.signupData.accountDetails.phone.phone_number)) || this.signupData.accountDetails.phone.phone_number.length > 16) {
				this.phoneNumberError = this.translateService.getTranslationText('signup.phoneNumberError');;
				isPassed = false;
			}
		}

		const passwordTextRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/; // At least 8 characters of 3 types: Lower case, Upper case and Number
		if (!this.signupData.accountDetails.password) {
			this.passwordError = this.translateService.getTranslationText('ERRORS.requiredField');
			isPassed = false;
		}
		else if (!passwordTextRegex.test(this.signupData.accountDetails.password)) {
			this.passwordError = ' ';
			this.errMsg = this.translateService.getTranslationText('signup.passwordError');
			isPassed = false;
		}

		if (this.reTypePassword && this.signupData.accountDetails.password && this.reTypePassword !== this.signupData.accountDetails.password) {
			this.reTypePasswordError = this.translateService.getTranslationText('signup.passwordErrorNotMatch');
			isPassed = false;
		}
		else if (!this.reTypePassword) {
			this.reTypePasswordError = this.translateService.getTranslationText('ERRORS.requiredField');
			isPassed = false;
		}

		if (!this.signupData.accountDetails.email) {
			this.emailError = this.translateService.getTranslationText('ERRORS.requiredField');
			isPassed = false;
		}
		else if (!this.gs.validateEmail(this.signupData.accountDetails.email)) {
			this.emailError = this.translateService.getTranslationText('signup.emailAddressError');
			isPassed = false;
		}

		return isPassed;
	}

	continue() {
		this.errMsg = '';

		if (!this.validateSignUpForm()) {
			return;
		}
		
		if (this.signupState === dic.CONSTANTS.signupState.fillForm) {
			this.signingUpInProcess = true;

			this.rs.signupUser(this.signupData.accountDetails).then((response) => {
				if (response.error) {
					this.signingUpInProcess = false;
					this.errMsg = response.message;
				}
				else {
					this.signingUpInProcess = false;
					this.signupState = dic.CONSTANTS.signupState.success;
					if (response.access_token) {
						this.signupCompletionData = {
							expires_at: response.expires_in,
							accessToken: response.access_token,
							email: this.signupData.accountDetails.email
						};
					}
				}
			}, (error) => {
				this.signingUpInProcess = false;
				this.errMsg = error.data.message;
			});
		}
	}

	navigateToAppAfterComplete() {
		if (this.signupCompletionData) {
			this.navigateToAppAfterCompleteInProcess = true;
			this.authService.remoteLogin(this.signupCompletionData, true);
		}
	}

	backToLogin() {
		this.cancelCb.emit();
	}

	validatePasswordComplexity() {
		this.validPassword = this.passwordRegex.test(this.signupData.accountDetails.password);
	}
}
