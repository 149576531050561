import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import * as _ from 'lodash';

@Pipe({name: 'safeHtml'})
export class safeHtml implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(style) {
		return this.sanitizer.bypassSecurityTrustHtml(style);
	}
}

@Pipe({name: 'formatBytes'})
export class formatBytes implements PipeTransform {
	transform(bytes, decimals) {
		if (bytes === 0) return '0 Bytes';
		let k = 1024,
			dm = decimals || 2,
			sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
			i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
}

@Pipe({name: 'getTrackingEventStatus'})
export class getTrackingEventStatus implements PipeTransform {
	transform(events, eventName) {
		const event: any = _.find(events, {name: eventName});
		if (event) {
			return event.status;
		}
		return 'none';
	}
}

@Pipe({name: 'getTrackingEventInfo'})
export class getTrackingEventInfo implements PipeTransform {
	transform(events, eventName) {
		const event: any = _.find(events, {name: eventName});
		if (event) {
			return event;
		}
		return {};
	}
}

@Pipe({name: 'orderBy'})
export class orderBy implements PipeTransform {
	transform( array: Array<any>, orderField: string, orderType: boolean ): Array<string> {
		array.sort( ( a: any, b: any ) => {
			let ae = a[ orderField ];
			let be = b[ orderField ];
			if ( ae == undefined && be == undefined ) return 0;
			if ( ae == undefined && be != undefined ) return orderType ? 1 : -1;
			if ( ae != undefined && be == undefined ) return orderType ? -1 : 1;
			if ( ae == be ) return 0;
			return orderType ? (ae.toString().toLowerCase() > be.toString().toLowerCase() ? -1 : 1) : (be.toString().toLowerCase() > ae.toString().toLowerCase() ? -1 : 1);
		} );
		return array;
	}
}
