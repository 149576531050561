import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import _ from 'lodash';
import {DropdownComponent} from "../dropdown/dropdown.component";
import {GeneralService} from "../../../services/general.service";
import {CountryService} from "ngx-international-phone-number";

@Component({
	selector: 'country-code-select',
	templateUrl: './country-code-select.component.html',
})
export class CountryCodeSelectComponent implements OnInit{
	@Input() model;
	@Output() modelChange = new EventEmitter<any>();
	@Input() allowedCountries = this.gs.allowedCountries;
	@Input() isDisabled;
	@ViewChild(DropdownComponent) dropdownEl:DropdownComponent;

	constructor(private gs: GeneralService, private countryService: CountryService) {
		this.initCountries();
	}

	countries = [];
	selectedCountry;

	ngOnInit() {
		const selectedCountry = this.model ? _.find(this.countries, {dialCode: this.model.replace(/\+/g, '')}) : _.find(this.countries, {countryCode: this.gs.defaultCountry});
		this.selectCountryCode(selectedCountry);
	}

	initCountries = () => {
		this.countries = _.cloneDeep(this.countryService.getCountries());
		this.countries = _.filter(this.countries, country => this.allowedCountries.includes(country.countryCode));
		this.countries.forEach(countryObj => {
			countryObj.icon = 'flag-icon flag-' + countryObj.countryCode;
			if (countryObj.name.indexOf(' (') > 1) {
				countryObj.name = countryObj.name.substring(0, countryObj.name.indexOf(' ('));
			}
		});
	}

	selectCountryCode = (selectedCountry) => {
		if (!selectedCountry) {
			return;
		}

		this.selectedCountry = selectedCountry;
		this.modelChange.emit(selectedCountry.dialCode);
	}
}
