import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import {Restangular, RestangularModule} from 'ngx-restangular';
import {TooltipModule, TooltipOptions} from 'ng2-tooltip-directive';
import {MomentModule} from 'ngx-moment';
import {ClickOutsideModule} from 'ng4-click-outside';
import {NgKnifeModule} from 'ng-knife';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {ENV_CONSTS, Restangular_EmailAccess, Restangular_Graph, Restangular_ScanLinks, Restangular_ScanFiles, Restangular_ScanHeaders, Restangular_Attachments} from './constants';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
import {AuthService} from './services/auth.service';
import {Office365Service} from './services/office365.service';
import {GeneralService} from './services/general.service';
import {TranslationService} from './services/translation.service';
import {RestService} from './services/rest.service';
import {DownloadFilesService} from './services/download-files.service';
import { SignupFormComponent } from './components/signup/signup-form.component';
import {InternationalPhoneNumberModule, CountryService} from 'ngx-international-phone-number';
import { AdvancedComponent } from './components/advanced/advanced.component';
import { EditContactComponent } from './components/edit-contact/edit-contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { MailboxComponent } from './components/mailbox/mailbox.component';
import { MessagePostmarkComponent } from './components/mailbox/message-postmark/message-postmark.component';
import { MessageTrackingComponent } from './components/mailbox/message-tracking/message-tracking.component';
import { MessageInboundShieldComponent } from './components/mailbox/message-inbound-shield/message-inbound-shield.component';
import { MessageAuthenticationComponent } from './components/mailbox/message-authentication/message-authentication.component';
import { MessageSensitivityComponent } from './components/mailbox/message-sensitivity/message-sensitivity.component';
import {formatBytes, getTrackingEventInfo, getTrackingEventStatus, orderBy, safeHtml} from './pipes';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { SupportComponent } from './components/support/support.component';
import { ComposeComponent } from './components/compose/compose.component';
import { FileUploadModule } from 'ng2-file-upload';
import {AutoFocusDirective, scrollDrv, SelectAllText} from './directives/directives';
import { SearchDropdownComponent } from './components/search-dropdown/search-dropdown.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClipboardModule} from 'ngx-clipboard';
import { AttachmentsManagerComponent } from './components/attachments-manager/attachments-manager.component';
import { ToggleSwitchComponent } from './components/uiComponents/toggle-switch/toggle-switch.component';
import { PinCodeComponent } from './directives/pin-code/pin-code.component';
import {ContentComponent} from "./components/content/content.component";
import {AutoCompleteMenuComponent} from "./components/uiComponents/autoCompleteMenu/auto-complete-menu.component";
import {VirtualScrollerModule} from "ngx-virtual-scroller";
import {DropdownComponent} from "./components/uiComponents/dropdown/dropdown.component";
import {BtnComponent} from "./components/uiComponents/btn/btn.component";
import {CountryCodeSelectComponent} from "./components/uiComponents/country-code-select/country-code-select.component";


const defaultTooltipOptions: TooltipOptions = {
	'hide-delay': 0
};

export function RestangularConfigFactory(RestangularProvider, restangular: Restangular) {
	RestangularProvider.setBaseUrl(ENV_CONSTS.beBaseUrl);
	RestangularProvider.setPlainByDefault(true);
	RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {
		if (response.status === 406) {
			RestangularProvider.configuration.require2FA.next(response.data);
			// error handled
			// return false;
		} else if (response.status === 401) {
			response.data.error = 401;
			RestangularProvider.configuration.blockUser.next(response.data);
		}
		// error not handled
		return true;
	});
}

export function RestangularEmailAccessFactory(restangular: Restangular) {
	return restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.beBaseAccessUrl);
	});
}

export function RestangularGraphFactory(restangular: Restangular) {
	return restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.beBaseGraphUrl);
	});
}

export function RestangularScanLinksFactory(restangular: Restangular) {
	return restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.scanLinksThreatsApp);
	});
}

export function RestangularScanFilesFactory(restangular: Restangular) {
	return restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.scanFilesThreatsApp);
	});
}

export function RestangularScanHeadersFactory(restangular: Restangular) {
	return restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.scanHeadersThreatsApp);
	});
}

export function RestangularAttachmentsFactory(restangular: Restangular) {
	return restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.beBaseAttachmentUrl);
	});
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './i18n/', '.json');
}

@NgModule({
	declarations: [
		safeHtml,
		formatBytes,
		getTrackingEventInfo,
		getTrackingEventStatus,
		orderBy,
		AppComponent,
		ContentComponent,
		LoginComponent,
		SignupFormComponent,
		AdvancedComponent,
		EditContactComponent,
		FooterComponent,
		MailboxComponent,
		MessagePostmarkComponent,
		MessageTrackingComponent,
		MessageInboundShieldComponent,
		MessageAuthenticationComponent,
		HtmlEditorComponent,
		SupportComponent,
		ComposeComponent,
		DropdownComponent,
		BtnComponent,
		CountryCodeSelectComponent,
		AutoCompleteMenuComponent,
		AutoFocusDirective,
		SelectAllText,
		scrollDrv,
		SearchDropdownComponent,
		MessageSensitivityComponent,
		AttachmentsManagerComponent,
		ToggleSwitchComponent,
		PinCodeComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RestangularModule.forRoot(RestangularConfigFactory),
		HttpClientModule,
		TooltipModule.forRoot(defaultTooltipOptions as TooltipOptions),
		MomentModule,
		ClickOutsideModule,
		VirtualScrollerModule,
		NgKnifeModule,
		InternationalPhoneNumberModule,
		FileUploadModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		BrowserAnimationsModule,
		ClipboardModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			defaultLanguage: 'us'
		}),
	],
	providers: [
		AuthService,
		Office365Service,
		GeneralService,
		RestService,
		DownloadFilesService,
		TranslationService,
		CountryService,
		{ provide: Restangular_EmailAccess, useFactory:  RestangularEmailAccessFactory, deps: [Restangular] },
		{ provide: Restangular_Graph, useFactory:  RestangularGraphFactory, deps: [Restangular] },
		{ provide: Restangular_ScanLinks, useFactory:  RestangularScanLinksFactory, deps: [Restangular] },
		{ provide: Restangular_ScanFiles, useFactory:  RestangularScanFilesFactory, deps: [Restangular] },
		{ provide: Restangular_ScanHeaders, useFactory:  RestangularScanHeadersFactory, deps: [Restangular] },
		{ provide: Restangular_Attachments, useFactory:  RestangularAttachmentsFactory, deps: [Restangular] }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
