import {Injectable} from '@angular/core';
import { dic } from '../dictionary';
import {Subject} from 'rxjs';
import util from 'util';
import {TranslationService} from "./translation.service";

@Injectable({
	providedIn: 'root'
})
export class NotificationService {

	notification = {
		message: '',
		type: null
	};

	private updateGlobalNotificationSubject = new Subject<boolean>();
	updateGlobalNotification$ = this.updateGlobalNotificationSubject.asObservable();

	updateGlobalNotification(data: any) {
		this.updateGlobalNotificationSubject.next(data);
	}

	constructor(private translateService: TranslationService) {
	}

	private init = () => {
		this.notification = {
			message: '',
			type: null
		};
	}

	showErrorMessage = (msg, ...messageParams: any[]) => {
		if (!msg) { return; }
		if (!this.notification) {
			this.init();
		}
		this.notification.type = dic.CONSTANTS.NotificationTypes.ERROR;
		this.translateService.getTranslatedError(msg, (txtMsg) => {
			if (messageParams) {
				// @ts-ignore
				txtMsg = util.format(txtMsg, ...messageParams);
			}
			this.showMessage(txtMsg);
		});
	}


	showInfoMessage = (msg, ...messageParams: any[]) => {
		if (!msg) { return; }
		if (!this.notification) {
			this.init();
		}
		this.notification.type = dic.CONSTANTS.NotificationTypes.INFO;

		this.translateService.getTranslatedMessage(msg, (txtMsg) => {
			if (messageParams) {
				// @ts-ignore
				txtMsg = util.format(txtMsg, ...messageParams);
			}
			this.showMessage(txtMsg);
		});
	}

	showMessage = (msg) => {
		this.notification.message = msg;
		this.updateGlobalNotification(this.notification);
	}

	notificationPriority = () => {
		const time = dic.CONSTANTS.notificationTimeout;
		if (this.notification && this.notification.message) {
			setTimeout(() => this.clear(), time);
		}
	}

	clear = () => {
		this.init();
		this.updateGlobalNotification(this.notification);
	}
}
