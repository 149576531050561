import env from '../env.json';
import {InjectionToken} from "@angular/core";

export const AUTH_EVENTS = {
	twoFactorRequiredError : [406]
};

export const ERROR_EVENTS = {
	clientError : [400],
	serverError : [500],
	authError: [401, 403],
	success : [200, 201],
	sessionTO: [419, 440],
	tooManyReq: [429],
};

export const AUTH0_CONSTS = {
	domain: 'trustifi.auth0.com',
	clientId: env[env.environment]['auth0'].appId,
	connection: env[env.environment]['auth0'].connection,
	auth0RedirectUrl: 'https://trustifi.auth0.com/login/callback',
	MSConnection: 'trustifi-microsoft',	    //trustificorp-azuread
	MSAppId: env[env.environment]['auth0'].MSAppId
};

export const ENV_CONSTS = {
	country_code: env[env.environment]['country_code'],
	corpLinks: env[env.environment]['resources'],
	feBaseUrl: env[env.environment]['webapp'].url,
	beBaseUrl: env[env.environment]['backend'].url + '/api/i/v1',
	beBaseAttachmentUrl: env[env.environment]['backend'].attachment + '/api/i/v1',
	beBaseGraphUrl: env[env.environment]['graph'].url + '/api/i/v1',
	scanLinksThreatsApp: env[env.environment]['scanLinksThreatsApp'].url + '/api/i/v1',
	scanFilesThreatsApp: env[env.environment]['scanFilesThreatsApp'].url + '/api/i/v1',
	scanHeadersThreatsApp: env[env.environment]['scanHeadersThreatsApp'].url + '/api/i/v1',
	beBaseGraphUrlOutbound: env[env.environment]['graph'].url + '/api/o/v1',
	beBaseAccessUrl: env[env.environment]['backend'].url + '/api/o/v1',
	beBaseAccessAttachmentUrl: env[env.environment]['backend'].attachment + '/api/o/v1',
	accessAppUrl: env[env.environment]['emailaccessapp'].url,
	emailsuffix: env[env.environment]['backend'].emailsuffix,
	lf_info: env[env.environment]['lf_info'],
	images_replace: env[env.environment]['images_replace'],
	emailAccessURL: 'emailAccess',
	trustifiDefault: 'trustifi'
};

export const Restangular_EmailAccess = new InjectionToken<any>('RestangularEmailAccess');
export const Restangular_Graph = new InjectionToken<any>('RestangularGraph');
export const Restangular_ScanLinks = new InjectionToken<any>('RestangularScanLinks');
export const Restangular_ScanFiles = new InjectionToken<any>('RestangularScanFiles');
export const Restangular_ScanHeaders = new InjectionToken<any>('RestangularScanHeaders');
export const Restangular_Attachments = new InjectionToken<any>('RestangularAttachments');

