import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { dic } from '../dictionary';
import util from "util";

declare var Office : any;

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    isWindowsDesktop = Office.context.mailbox.diagnostics.hostName !== 'OutlookWebApp' && window.navigator.platform.indexOf('Win') >= 0;
    selectedAppLang = dic.CONSTANTS.supportedLanguages.english;

    constructor(private translate: TranslateService) {}
    
    initLanguage = () => {
        this.translate.setDefaultLang('us');

        // this language will be used as a fallback when a translation isn't found in the current language
        const localStorageLangCode = this.roamingGet(dic.CONSTANTS.localStorageLang);
        if (localStorageLangCode) {
            for (const langKey in dic.CONSTANTS.supportedLanguages) {
                if (dic.CONSTANTS.supportedLanguages[langKey].code === localStorageLangCode) {
                    this.selectedAppLang = dic.CONSTANTS.supportedLanguages[langKey];
                    break;
                }
            }
        }

        this.changeLanguageApp(this.selectedAppLang);
    }


    changeLanguageApp(lang) {
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.selectedAppLang = lang || this.selectedAppLang;
        this.translate.use(this.selectedAppLang.code);
        this.roamingSet(dic.CONSTANTS.localStorageLang, this.selectedAppLang.code, ()=>{});
    }

    getTranslatedMessage = (msg, cb) => {
        if (!msg) { return; }

        let txtMsg = msg;
        this.translate.get(`MESSAGES.${msg}`).subscribe((translatedTxt: string) => {
            if (translatedTxt && !translatedTxt.startsWith('MESSAGES.')) {
                txtMsg = translatedTxt;
            }
            cb(txtMsg);
        });
    }

    getTranslatedError = (msg, cb) => {
        if (!msg) { return; }

        let txtMsg = msg;
        this.translate.get(`ERRORS.${msg}`).subscribe((translatedTxt: string) => {
            if (translatedTxt && !translatedTxt.startsWith('ERRORS.')) {
                txtMsg = translatedTxt;
            }
            cb(txtMsg);
        });
    }

    getTranslationText = (text, ...messageParams: any[]) => {
        let translatedValue = this.translate.instant(text);
        if (messageParams) {
            // @ts-ignore
            translatedValue = util.format(translatedValue, ...messageParams);
        }
        return translatedValue;
    }

    //#9394 - Duplicated code because of the cyclic dependencies: translation->office365->general->translation
    private roamingGet(name) {
        let result = Office.context.roamingSettings.get('trustifi.' + name);
        if (!result && this.isWindowsDesktop) {
            result = window.localStorage.getItem('trustifi.' + name);
            try {
                if (result) {
                    result = JSON.parse(result);
                    this.roamingSet(name, result, () => {});
                }
            }
            catch(ex) {
                result = null;
            }
        }

        return result;
    }

    private roamingSet(name, data, callback) {
        Office.context.roamingSettings.set('trustifi.' + name, data);
        if(this.isWindowsDesktop) window.localStorage.setItem('trustifi.' + name, JSON.stringify(data));

        try {
            Office.context.roamingSettings.saveAsync(result => {
                if (result.status === Office.AsyncResultStatus.Failed) {
                    console.error('[Trustifi] roamingSettings error: ', result);
                }

                callback(result);
            });
        }
        catch(ex) {
            callback({status: Office.AsyncResultStatus.Failed, error: ex });
        }
    }
}
