import {Component, Input, OnInit} from '@angular/core';
import {ENV_CONSTS} from "../../../constants";
import {Subject} from "rxjs";

@Component({
	selector: 'app-message-postmark',
	templateUrl: './message-postmark.component.html',
	styleUrls: ['./message-postmark.component.scss']
})
export class MessagePostmarkComponent implements OnInit {

	@Input() recipientPostmark;
	@Input() currentMessage;
	@Input() contentWidth;
	@Input() trustifiDefault;

	postmarkDataSubject: Subject<Array<object>> = new Subject<Array<object>>();

	recipient: any;

	constructor() { }

	ngOnInit() {
		this.setGlobalTimes();
		setTimeout(() => {
			this.postmarkDataSubject.next(this.currentMessage.postmarkData);
		});
	}

	changePostmarkRecipient(track: any) {
		if (this.recipientPostmark.recipient.id === track.recipient.id) return;
		this.recipientPostmark = track;
		this.setGlobalTimes();
	}

	summerWinterOffset(postmarkDate) {
		const jan = new Date(postmarkDate.getFullYear(), 0, 1);
		const jul = new Date(postmarkDate.getFullYear(), 6, 1);
		const stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
		return (stdTimezoneOffset - postmarkDate.getTimezoneOffset()) / 60
	}

	setGlobalTimes(){
		if (!this.recipientPostmark || !this.recipientPostmark.timestamp) return;
		let postmarkDate = new Date(this.recipientPostmark.timestamp);
		const summerWinterOffset = this.summerWinterOffset(postmarkDate);
		//calculate offset from utc, summerWinterOffset, and -5 for EST
		postmarkDate.setHours(postmarkDate.getHours() + (postmarkDate.getTimezoneOffset() / 60) - 5 + summerWinterOffset);
		this.recipientPostmark.est = postmarkDate.toISOString();
		//cst is -1 hour from est
		postmarkDate.setHours(postmarkDate.getHours() - 1);
		this.recipientPostmark.cst = postmarkDate.toISOString();
		postmarkDate.setHours(postmarkDate.getHours() - 1);
		this.recipientPostmark.mst = postmarkDate.toISOString();
		postmarkDate.setHours(postmarkDate.getHours() - 1);
		this.recipientPostmark.pst = postmarkDate.toISOString();
	};
}
